import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import truncate from 'lodash/truncate'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import invariant from 'tiny-invariant'

import useAppSelector from '../../../../hooks/useAppSelector'
import { useCurrentUserOrFail } from '../../../../hooks/useCurrentUserOrFail'
import { selectIsUpdatingContext } from '../../../../redux/slices/session'
import {
  selectCompanies,
  selectCurrentCompany,
  selectHasCompanyAccount,
  selectIsAuthenticatedClient,
} from '../../../../redux/slices/session'
import UserCompanies from '../../DashboardLayout/components/Navbar/UserCompanies'

const AccountDropdown: React.FC = () => {
  const currentUser = useCurrentUserOrFail()
  const currentCompany = useAppSelector(selectCurrentCompany)
  const companies = useAppSelector(selectCompanies)
  const isAuthenticatedClient = useAppSelector(selectIsAuthenticatedClient)
  const hasCompanyAccount = useAppSelector(selectHasCompanyAccount)
  const isSwitchingCompany = useAppSelector(selectIsUpdatingContext)

  function renderCurrentCompanyName() {
    if (!currentCompany) {
      return ''
    }

    if (isSwitchingCompany) {
      return '(Updating...)'
    }

    const name = currentCompany.name
    invariant(name, `Company ${currentCompany['@id']} has no name`)

    const truncatedName =
      name.length > 25 ? name.substring(0, 25) + '...' : name
    return `| ${truncatedName}`
  }

  const showDropdown = companies.length > 1

  return (
    <Dropdown className="nav-item" align="end">
      <div className="d-sm-inline-block">
        <span className="d-sm-inline-block">
          <Dropdown.Toggle
            as="a"
            className={classNames({
              'nav-link': true,
              'cursor-default': !showDropdown,
              'hide-caret': !showDropdown,
            })}
            role="button"
          >
            <span className="text-dark">
              <FontAwesomeIcon icon={faUser} className="me-2 mt-1" />

              <span>{truncate(currentUser.firstName, { length: 30 })} </span>

              <span className="muted text-sm">
                {renderCurrentCompanyName()}

                {isAuthenticatedClient && hasCompanyAccount && (
                  <span>(Client)</span>
                )}
              </span>
            </span>
          </Dropdown.Toggle>
        </span>

        {showDropdown && (
          <Dropdown.Menu align="end">
            {hasCompanyAccount && <UserCompanies />}
          </Dropdown.Menu>
        )}
      </div>
    </Dropdown>
  )
}

export default AccountDropdown
