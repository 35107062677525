import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import React from 'react'
import { Button, Card } from 'react-bootstrap'

import { useIsPdf } from '../../hooks/useIsPdf'

export type FieldArrayItemProps = {
  name: string
  index: number
}

interface Props {
  name: string
  items: unknown[]
  itemName: string
  renderItem: (props: FieldArrayItemProps) => React.ReactNode
  addButtonText?: string
  showAddButton?: boolean
  defaultOption: unknown
  onRemove?: () => void
  minNumItems?: number
  maxNumItems?: number
  hideRemoveButton?: boolean
  removeButtonText?: string
  isDisabled?: boolean
}

const FieldArrayInput: React.FC<Props> = ({
  name,
  items,
  itemName,
  renderItem,
  addButtonText,
  showAddButton = true,
  defaultOption,
  onRemove,
  minNumItems = 0,
  maxNumItems = 15,
  hideRemoveButton = false,
  removeButtonText = 'Delete',
  isDisabled,
}) => {
  const isPdf = useIsPdf()

  function handleRemove(arrayHelpers: FieldArrayRenderProps, index: number) {
    arrayHelpers.remove(index)

    if (onRemove) {
      onRemove()
    }
  }

  const shouldShowAddButton =
    !isDisabled && showAddButton && items.length < maxNumItems

  const shouldShowRemoveButton = !isDisabled && items.length > minNumItems

  return (
    <div className="my-3">
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          if (!items) {
            return null
          }

          return (
            <>
              {items.map((_, index) => (
                <div key={index} className="avoid-page-break">
                  <Card className={classNames('mb-3', { 'card-plain': isPdf })}>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h4 className="mb-0 text-black fw-bolder">
                          {itemName} {index + 1}
                        </h4>

                        {/* Only show delete button if numItems is >= minNumItems*/}
                        {shouldShowRemoveButton && (
                          <Button
                            className={classNames({
                              'd-none': hideRemoveButton,
                            })}
                            variant="link text-danger py-0"
                            onClick={() => handleRemove(arrayHelpers, index)}
                          >
                            {removeButtonText}
                          </Button>
                        )}
                      </div>

                      {renderItem({ name, index })}
                    </Card.Body>
                  </Card>
                </div>
              ))}

              {shouldShowAddButton && (
                <Button
                  variant="link text-decoration-none"
                  onClick={() => {
                    arrayHelpers.push(defaultOption)
                  }}
                  className="mt-n1 d-flex align-items-center ps-0"
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="me-2"
                    style={{ fontSize: '16px' }}
                  />{' '}
                  <span>{addButtonText || 'Add'}</span>
                </Button>
              )}
            </>
          )
        }}
      />
    </div>
  )
}

export default FieldArrayInput
