import {
  IncomeInputFields,
  MONTHLY_OUTGOINGS_FOR,
  MonthlyOutgoingsFor,
} from '../../../components/flows/CompassFlow/steps/11_Income/constants'
import { IncomeInput } from '../../../components/flows/CompassFlow/utils/types'

class CompassIncomeService {
  getOutgoingsBreakdownFields(
    monthlyOutgoingsFor: MonthlyOutgoingsFor,
  ): BreakdownField[] {
    const rentField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_rent,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_rent,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_rent,
    }[monthlyOutgoingsFor]

    const householdField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_household,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_household,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_household,
    }[monthlyOutgoingsFor]

    const carFinanceField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_car_finance,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_car_finance,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_car_finance,
    }[monthlyOutgoingsFor]

    const carOtherField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_car_other,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_car_other,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_car_other,
    }[monthlyOutgoingsFor]

    const foodField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_food,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_food,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_food,
    }[monthlyOutgoingsFor]

    const personalInsurancesField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_personal_insurances,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_personal_insurances,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_personal_insurances,
    }[monthlyOutgoingsFor]

    const loansAndCreditCardsField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_loans_and_credit_cards,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_loans_and_credit_cards,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_loans_and_credit_cards,
    }[monthlyOutgoingsFor]

    const childrenExpensesField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_children_expenses,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_children_expenses,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_children_expenses,
    }[monthlyOutgoingsFor]

    const personalExpensesField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_personal_expenses,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_personal_expenses,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_personal_expenses,
    }[monthlyOutgoingsFor]

    const savingsField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_savings,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_savings,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_savings,
    }[monthlyOutgoingsFor]

    const holidaysField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_holidays,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_holidays,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_holidays,
    }[monthlyOutgoingsFor]

    const havingFunField = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_having_fun,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_having_fun,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_having_fun,
    }[monthlyOutgoingsFor]

    const miscFields = {
      [MONTHLY_OUTGOINGS_FOR.user]:
        IncomeInputFields.user_monthly_outgoings_misc,
      [MONTHLY_OUTGOINGS_FOR.partner]:
        IncomeInputFields.partner_monthly_outgoings_misc,
      [MONTHLY_OUTGOINGS_FOR.joint]:
        IncomeInputFields.joint_monthly_outgoings_misc,
    } satisfies Record<string, keyof IncomeInput>

    const miscField = miscFields[monthlyOutgoingsFor]

    return [
      { label: 'Rent', name: rentField },
      { label: 'Household bills and expenses', name: householdField },
      {
        label: 'Car finance',
        name: carFinanceField,
      },
      {
        label: 'Other car-related costs (excluding any car finance)',
        name: carOtherField,
      },
      { label: 'Food', name: foodField },
      { label: 'Personal Insurances', name: personalInsurancesField },
      { label: 'Credit Cards', name: loansAndCreditCardsField },
      { label: "Children's Expenses", name: childrenExpensesField },
      { label: 'Personal Expenses', name: personalExpensesField },
      { label: 'Savings', name: savingsField },
      { label: 'Holidays', name: holidaysField },
      { label: 'Having fun', name: havingFunField },
      { label: 'Miscellaneous', name: miscField },
    ]
  }

  calculateMonthlyOutgoingsTotal(
    values: Partial<IncomeInput>,
    monthlyOutgoingsFor: MonthlyOutgoingsFor,
  ): number {
    const breakdownFields =
      this.getOutgoingsBreakdownFields(monthlyOutgoingsFor)

    // TODO: Avoid the cast
    return breakdownFields.reduce((totalAmount, field) => {
      return (
        totalAmount +
        (Number(values[field.name as keyof IncomeInput] || '') || 0)
      )
    }, 0)
  }
}

interface BreakdownField {
  label: string
  name: keyof IncomeInput
}

const compassIncomeService = new CompassIncomeService()

export default compassIncomeService
