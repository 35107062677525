import React, { useRef } from 'react'
import { useMount } from 'react-use'

interface Props {
  children: React.ReactNode
  variant: 'dark' | 'body-bg' | 'white'
  contentCentered?: boolean
}
const Overlay: React.FC<Props> = ({ children, variant, contentCentered }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const backgroundColor =
    variant === 'dark'
      ? 'color-mix(in srgb, black 70%, transparent)'
      : variant === 'body-bg'
        ? 'color-mix(in srgb, var(--bs-body-bg) 50%, transparent)'
        : 'color-mix(in srgb, white 50%, transparent)'

  // Scroll the overlay card into view if it's not visible
  useMount(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  })

  return (
    <div
      ref={containerRef}
      style={{
        position: 'absolute',
        zIndex: 9999999,
        top: '-1rem',
        left: '-1rem',
        width: 'calc(100% + 2rem)',
        height: 'calc(100% + 2rem)',
        padding: '1rem',
        backdropFilter: 'blur(5px)',
        backgroundColor: backgroundColor,
      }}
      className={
        contentCentered
          ? 'd-flex justify-content-center align-items-center'
          : ''
      }
    >
      {children}
    </div>
  )
}

export default Overlay
