import { STATE_KEY } from '../lib/constants'
import { StateKey, StateValue } from '../types/misc'
import { useAppStateQuery } from './useAppStateQuery'

export function useAppState() {
  const appStateQuery = useAppStateQuery()
  const states = appStateQuery.data?.['hydra:member'] || []

  return {
    hasInteractedWithFirstRelationshipRequestApprovedModal: hasStateValue(
      STATE_KEY.hasInteractedWithFirstRelationshipRequestApprovedModal,
      true,
    ),

    hasNotInteractedWithFirstRelationshipRequestApprovedModal: hasStateValue(
      STATE_KEY.hasInteractedWithFirstRelationshipRequestApprovedModal,
      false,
    ),

    hasWatchedDemoVideo: hasStateValue(STATE_KEY.hasWatchedDemoVideo, true),

    hasInteractedWithRqCertifiedAnnouncement: hasStateValue(
      STATE_KEY.hasInteractedWithRqCertifiedAnnouncement,
      true,
    ),

    hasClientReviewedPartners: hasStateValue(
      STATE_KEY.hasClientReviewedPartners,
      true,
    ),

    panelApprovedNew: normalizeAsArrayOfNumbers(STATE_KEY.panelApprovedNew),
    panelApprovedUpdated: normalizeAsArrayOfNumbers(
      STATE_KEY.panelApprovedUpdated,
    ),
    panelRequestedNew: normalizeAsArrayOfNumbers(STATE_KEY.panelRequestedNew),
    panelRequestedUpdated: normalizeAsArrayOfNumbers(
      STATE_KEY.panelRequestedUpdated,
    ),

    referralFromNew: normalizeAsArrayOfNumbers(STATE_KEY.referralFromNew),
    referralFromUpdated: normalizeAsArrayOfNumbers(
      STATE_KEY.referralFromUpdated,
    ),
    referralToNew: normalizeAsArrayOfNumbers(STATE_KEY.referralToNew),
    referralToUpdated: normalizeAsArrayOfNumbers(STATE_KEY.referralToUpdated),
  }

  function getByStateKey(stateKey: StateKey) {
    return states.find((state) => state.stateKey === stateKey)
  }

  function hasStateValue(stateKey: StateKey, stateValue: StateValue): boolean {
    return states.some(
      (state) => state.stateKey === stateKey && state.stateValue === stateValue,
    )
  }

  function normalizeAsArrayOfNumbers(stateKey: StateKey): number[] {
    const value = getByStateKey(stateKey)?.stateValue
    return Array.isArray(value) ? value.map(Number) : []
  }
}
