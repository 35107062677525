import React from 'react'
import { Navigate } from 'react-router-dom'

import { useHomeRoute } from '../../hooks/useHomeRoute'
import { isDevelopment } from '../../lib/helpers/helperFunctions'

interface Props {
  children: React.ReactNode
}

const DevGuard: React.FC<Props> = ({ children }) => {
  const homeRoute = useHomeRoute()

  if (!isDevelopment()) {
    return <Navigate to={homeRoute} replace />
  }

  return <>{children}</>
}

export default DevGuard
