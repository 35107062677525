import React from 'react'

import useSettingsState from '../hooks/useSettingsState'
import { LAYOUT } from '../lib/constants'

/* eslint-disable unused-imports/no-unused-vars */
const initialState = {
  layout: LAYOUT.BOXED,
  setLayout: (layout: string) => {},
}

const LayoutContext = React.createContext(initialState)

interface Props {
  children: React.ReactNode
}

const LayoutProvider: React.FC<Props> = ({ children }) => {
  const [layout, setLayout] = useSettingsState('layout', LAYOUT.BOXED)

  return (
    <LayoutContext.Provider value={{ layout, setLayout }}>
      {children}
    </LayoutContext.Provider>
  )
}

export { LayoutContext, LayoutProvider }
