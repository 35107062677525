import React from 'react'

import { EMAIL_ADDRESSES } from '../../../lib/constants'
import { filterText, stripTags } from '../../../pages/inviteToRq/helpers'

interface Props {
  email: string
  subject: string
  body: string
  children: string
  className?: string
}

const MailTo: React.FC<Props> = ({
  email,
  subject,
  body,
  children,
  className = 'btn btn-outline-secondary btn-lg',
}) => {
  body = filterText(body)

  // @todo use html-to-text instead
  body = stripTags(body)

  const url = `mailto:${email}?cc=${EMAIL_ADDRESSES.rqHello}&subject=${
    encodeURIComponent(subject) || ''
  }&body=${encodeURIComponent(body) || ''}`

  return (
    <div
      role="button"
      onClick={async () => {
        document.location.href = url
      }}
      className={className}
    >
      {children}
    </div>
  )
}

export default MailTo
