import { useSearchParams } from 'react-router-dom'

import { COMPANY_TYPE_CODE } from '../lib/constants'
import { isDevelopment } from '../lib/helpers/helperFunctions'
import useCurrentCompanyOrFail from './useCurrentCompanyOrFail'

export function useCompleteRegistrationTypeformId() {
  const currentCompany = useCurrentCompanyOrFail()
  const companyTypeCode = currentCompany.companyType.code
  const [searchParams] = useSearchParams()

  // Allow overriding the typeformId in development. Useful when you want
  // to use a shorter form to test the flow.
  const typeformIdFromUrl = searchParams.get('typeformId')
  if (isDevelopment() && typeformIdFromUrl) {
    return typeformIdFromUrl
  }

  const formIds = {
    [COMPANY_TYPE_CODE.accountant]: 'iBTKE0wd',
    [COMPANY_TYPE_CODE.corporate_finance]: 'LRvQUwqS',
    [COMPANY_TYPE_CODE.ifa]: 'Q3c4fERW',
    [COMPANY_TYPE_CODE.insurance_adviser]: 'cPem3NTe',
    [COMPANY_TYPE_CODE.mortgage_adviser]: 'A96HIQsL',
    [COMPANY_TYPE_CODE.solicitor]: 'GkhL3Kki',
    [COMPANY_TYPE_CODE.investment_management]: 'sr2MQU1z',
    [COMPANY_TYPE_CODE.other]: 'tj8AeWKP',
  }

  return formIds[companyTypeCode] || ''
}
