import { ClientDetailsInput } from '../../pages/referClient/steps/4_ClientDetails/constants'
import { getToCompanyContactName } from '../../pages/referClient/steps/5a_SelectReferralMethod/helpers'
import {
  REFERRAL_CONSENT_TYPE,
  ReferralConsentType,
} from '../../redux/slices/referClient'
import {
  CompanyTypeCode,
  NormalReferralTypeOption,
  ReferralType,
  ReferralTypeOption,
} from '../../types/misc'
import { ClientItem } from '../../types/responses/clients'
import {
  CompanyTypeItem,
  ServiceAreaItem,
} from '../../types/responses/common-data'
import { CompassReportCollectionItem } from '../../types/responses/compass-reports'
import { ReferralEmailItem } from '../../types/responses/referrals'
import { REFERRAL_TYPE, REFERRAL_TYPE_OPTION } from '../constants'
import {
  arrayToCommaSeparatedString,
  startsWithVowel,
} from '../helpers/helperFunctions'
import companyTypeService from './companyTypeService'

class ReferClientService {
  hasClientConsent(consentType?: ReferralConsentType) {
    return !this.needsClientConsent(consentType)
  }

  needsClientConsent(consentType?: ReferralConsentType) {
    return consentType === REFERRAL_CONSENT_TYPE.yesESignature
  }

  isNormalReferralType(
    referralTypeOption?: ReferralTypeOption,
  ): referralTypeOption is NormalReferralTypeOption {
    return (
      !!referralTypeOption &&
      referralTypeOption !== REFERRAL_TYPE_OPTION.bookCallForClient
    )
  }

  isBookCallForClientReferralType(
    referralTypeOption?: ReferralTypeOption,
  ): boolean {
    return (
      !!referralTypeOption &&
      referralTypeOption === REFERRAL_TYPE_OPTION.bookCallForClient
    )
  }

  isDirectReferral(referralType?: ReferralTypeOption) {
    return (
      referralType === REFERRAL_TYPE_OPTION.direct ||
      referralType === REFERRAL_TYPE_OPTION.directCc
    )
  }

  clientNeedsToConsentToIntroductionEmail(options: {
    consentType?: ReferralConsentType
    referralType?: ReferralTypeOption
  }) {
    const { consentType, referralType } = options

    if (consentType === undefined) {
      return null
    }

    return (
      this.needsClientConsent(consentType) &&
      consentType !== REFERRAL_CONSENT_TYPE.yesESignature &&
      this.isDirectReferral(referralType)
    )
  }

  clientNeedsToSignESignatureLetter(options: {
    consentType?: ReferralConsentType
  }) {
    const { consentType } = options

    return consentType === REFERRAL_CONSENT_TYPE.yesESignature
  }

  normalizeReferralType(
    referralType?: ReferralTypeOption,
  ): ReferralType | undefined {
    if (!referralType) {
      return undefined
    }

    if (
      referralType === REFERRAL_TYPE_OPTION.directCc ||
      referralType === REFERRAL_TYPE_OPTION.direct ||
      referralType === REFERRAL_TYPE_OPTION.bookCallForClient ||
      referralType === REFERRAL_TYPE_OPTION.directPco
    ) {
      return REFERRAL_TYPE.direct
    }

    return referralType as ReferralType
  }

  buildUserEmailMessage(context: UserEmailMessageContext): string {
    const {
      client,
      clientDetails,
      referralType,
      toCompanyContactNames,
      selectedServiceAreas,
      compassReport,
      companyTypes,
      companyTypeCode,
    } = context

    const toCompanyContactName = getToCompanyContactName(toCompanyContactNames)
    const serviceAreaNames = selectedServiceAreas.map(
      (serviceArea) => serviceArea.name,
    )
    const serviceAreaNamesString = arrayToCommaSeparatedString(serviceAreaNames)
    const compassReportMessage = compassReport
      ? ` Please find background information on ${client.firstName}'s personal affairs in the attached snapshot. `
      : ' '

    const readableCompanyType = companyTypeService.getCompanyTypeByCode(
      companyTypes,
      companyTypeCode,
    )

    const messages: Record<NormalReferralTypeOption, string[]> = {
      direct: [
        `Dear ${toCompanyContactName},\n\n`,
        `Our client, ${client?.abbreviatedName} is in need of advice regarding ${serviceAreaNamesString}.${compassReportMessage}Are you able to please reach out to them on the below number / email address?`,
        clientDetails?.reason?.length ? `\n\n${clientDetails.reason}` : '',
      ],
      directCc: [
        `Dear ${toCompanyContactName},\n\n`,
        `${client?.firstName} ${client?.lastName} (cc'd) is after some advice on ${serviceAreaNamesString}.${compassReportMessage}Can I leave it with you to please reach out to ${client?.firstName}?`,
        clientDetails?.reason?.length ? `\n\n${clientDetails.reason}` : '',
      ],
      directPco: [
        `Dear ${client.firstName},\n\n`,
        'In order to get a Will organised, we have passed your details to Private Client Online. You will hear from them shortly.',
      ],
      indirect: [
        `Dear ${client.firstName},\n\n`,
        `You have requested advice concerning ${serviceAreaNamesString}. `,
        `Please see below for details of ${
          startsWithVowel(readableCompanyType.name) ? 'an' : 'a'
        } ${
          readableCompanyType.name
        } that I think will be able to assist. Feel free to schedule a call with them using the below 'Schedule a conversation' link.`,
      ],
    }

    const message = messages[referralType]

    return message.join('')
  }
}

const referClientService = new ReferClientService()

export interface UserEmailMessageContext {
  client: ClientItem
  clientDetails: ClientDetailsInput
  referralType: NormalReferralTypeOption
  toCompanyContactNames: ReferralEmailItem['toNames']
  selectedServiceAreas: ServiceAreaItem[]
  compassReport?: CompassReportCollectionItem
  companyTypes: CompanyTypeItem[]
  companyTypeCode: CompanyTypeCode
}

export default referClientService
