import { FallbackRender } from '@sentry/react'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useMount } from 'react-use'

import { useShowSentryReportDialog } from '../../hooks/useShowSentryReportDialog'
import ErrorMessage from './ErrorMessage'

interface Props {
  error: Parameters<FallbackRender>[0]
  fallbackComponent?: React.ReactNode
}

const FullPageErrorFallback: React.FC<Props> = ({
  error,
  fallbackComponent,
}) => {
  const showSentryReportDialog = useShowSentryReportDialog()

  useMount(() => {
    showSentryReportDialog(error.eventId)
  })

  if (fallbackComponent) {
    return fallbackComponent
  }

  return (
    <div
      className="mt-5 p-4 mx-auto d-flex justify-content-center"
      style={{ maxWidth: '800px' }}
    >
      <Card>
        <Card.Body>
          <ErrorMessage error={error} />
        </Card.Body>
      </Card>
    </div>
  )
}

export default FullPageErrorFallback
