import './CompleteRegistrationPopUpButton.css'

import { useMutation } from '@tanstack/react-query'
import { PopupButton } from '@typeform/embed-react'
import React from 'react'

import useAppDispatch from '../../../hooks/useAppDispatch'
import { useCompleteRegistrationTypeformId } from '../../../hooks/useCompleteRegistrationTypeformId'
import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import { useCurrentUserOrFail } from '../../../hooks/useCurrentUserOrFail'
import { useInvalidateOnboardingChecklistQuery } from '../../../hooks/useInvalidateOnboardingChecklistQuery'
import useNotyf from '../../../hooks/useNotyf'
import companyService from '../../../lib/services/companyService'
import { completeCompanyRegistration } from '../../../redux/slices/session'

interface Props {
  containerClassName?: string
  className: string
  children: React.ReactNode
}

const CompleteRegistrationPopUpButton: React.FC<Props> = ({
  containerClassName,
  className,
  children,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const currentUser = useCurrentUserOrFail()
  const notyf = useNotyf()
  const typeformId = useCompleteRegistrationTypeformId()
  const invalidateOnboardingChecklistQuery =
    useInvalidateOnboardingChecklistQuery()
  const dispatch = useAppDispatch()

  const completeRegistrationMutation = useMutation({
    mutationFn: () => companyService.completeRegistration(currentCompany.id),
    onSuccess: async () => {
      dispatch(completeCompanyRegistration())
      await invalidateOnboardingChecklistQuery()
      notyf.success('Registration successfully completed')
    },
    onError: () => {
      notyf.error('There was a problem completing your registration')
    },
  })

  if (currentCompany.isRegistrationComplete || !typeformId) {
    return null
  }

  return (
    <div className={containerClassName}>
      <PopupButton
        id={typeformId}
        onSubmit={() => completeRegistrationMutation.mutate()}
        className={className}
        hidden={{
          env: process.env.NODE_ENV,
          user_id: currentUser.id.toString(),
          user_email: currentUser.email,
          company_id: currentCompany.id.toString(),
          company_name: currentCompany.name,
          company_number: currentCompany.companyNumber ?? '',
        }}
      >
        {children}
      </PopupButton>
    </div>
  )
}

export default CompleteRegistrationPopUpButton
