/**
 * Helper functions for working with the Office.js API.
 *
 * Do not use Office imports - the library is loaded by external script
 */
import { LOCAL_STORAGE_KEYS } from '../constants'

export function isOffice(): boolean {
  return (
    typeof Office !== 'undefined' &&
    typeof Office.context !== 'undefined' &&
    typeof Office.context.host !== 'undefined'
  )
}

export function isOfficeUI(): boolean {
  return isOffice() && typeof Office.context.ui !== 'undefined'
}

export function isOfficeParent(): boolean {
  return (
    isOffice() &&
    isOfficeUI() &&
    typeof Office.context.ui.displayDialogAsync !== 'undefined'
  )
}

export function isHostOutlookApplication(): boolean {
  return Office.context.host === Office.HostType.Outlook
}

export function isOfficeWeb(): boolean {
  return Office.context.platform === Office.PlatformType.OfficeOnline
}

export function openOfficeDialog(
  uri: string,
  callback?:
    | ((asyncResult: Office.AsyncResult<Office.Dialog>) => void)
    | true
    | undefined,
  width = 1250,
  height = 1100,
  options?: Office.DialogOptions,
) {
  uri = window.location.origin + uri

  if (!isOfficeUI()) {
    console.error('Office.context.ui is not available, redirecting instead.')
    return window.location.assign(uri)
  }

  if (!Office.context.ui.displayDialogAsync) {
    console.error('Office.context.ui.displayDialogAsync is not available.')
    return
  }

  options = {
    ...options,
    promptBeforeOpen: false,
  }

  // Office JS doesn't support pixel widths however we can calculate it as a percentage
  if (width) {
    options = {
      ...options,
      width: Math.floor((width / window.screen.availWidth) * 100),
    }
  }

  if (height) {
    options = {
      ...options,
      height: Math.floor((height / window.screen.availHeight) * 100),
    }
  }

  if (callback === true) {
    callback = (asyncResult) => defaultDialog(asyncResult)
  } else if (callback === undefined) {
    callback = () => {
      return
    }
  }

  Office.context.ui.displayDialogAsync(uri, options, callback)
}

export function defaultDialog(asyncResult: Office.AsyncResult<Office.Dialog>) {
  const dialog = asyncResult.value
  dialog.addEventHandler(Office.EventType.DialogMessageReceived, async () => {
    dialog.close()
  })
}

export function setOfficeTokens(
  accessToken: string | null,
  refreshToken: string | null,
) {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.officeWebAccessToken)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.officeWebRefreshToken)

  if (isOfficeParent() && isOfficeWeb()) {
    // To limit the use of localstorage tokens we only allow this for web version of office add-in which can't read iframe cookies from auth.rqratings
    if (accessToken) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.officeWebAccessToken,
        accessToken,
      )
    }
    if (refreshToken) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.officeWebRefreshToken,
        refreshToken,
      )
    }
  }
}

export function getOfficeTokens() {
  return {
    accessToken: window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.officeWebAccessToken,
    ) as string,
    refreshToken: window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.officeWebRefreshToken,
    ) as string,
  }
}
