import React, { PropsWithChildren } from 'react'

import { useReferralWithESignatureDetailsQuery } from '../../hooks/useReferralWithESignatureDetailsQuery'
import errorTypeService from '../../lib/services/errorTypeService'
import InvalidOrExpiredPage from '../../pages/InvalidOrExpiredPage'
import LoadingWidget from '../widgets/LoadingWidget'

const ReferralWithESignatureDetailsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const referralWithESignatureQuery = useReferralWithESignatureDetailsQuery()

  if (referralWithESignatureQuery.isLoading) {
    return <LoadingWidget />
  }

  const error = referralWithESignatureQuery.error

  // A 404 error means the API wasn't able to find the referral using the
  // provided `callbackCode` search param
  if (errorTypeService.is404Error(error)) {
    return (
      <InvalidOrExpiredPage
        content={
          <p>
            This link is either invalid or has expired. If you already submitted
            the eSignature letter, there is nothing else for you to do.
          </p>
        }
      />
    )
  }

  if (error || !referralWithESignatureQuery.data) {
    throw new Error('Failed to load referral')
  }

  return children
}

export default ReferralWithESignatureDetailsProvider
