import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useMount } from 'react-use'

import { MAX_INTEGER_VALUE } from '../../lib/constants'
import { isOfficeUI } from '../../lib/helpers/officeHelpers'
import authService, {
  KeycloakSessionExtended,
} from '../../lib/services/authService'
import OverlayCard from './OverlayCard'

// Add this component outside SessionProvider so event is properly setup for capture before login
const LogoutWarning: React.FC = () => {
  const SECONDS_UNTIL_LOGOUT = 120

  const [isShowWarning, setShowWarning] = useState<boolean>(false)
  const [seconds, setSeconds] = useState(SECONDS_UNTIL_LOGOUT)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useMount(() => {
    authService.addEventListener('sessionExtended', (event: Event) => {
      const customEvent = event as CustomEvent<KeycloakSessionExtended>
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      const expiryTime = customEvent.detail.expiryTime
      if (expiryTime) {
        const currentTime = Math.floor(Date.now() / 1000)
        const secondsRemaining = expiryTime - currentTime

        if (secondsRemaining <= 0) {
          // Has already been logged out - perhaps page was left in the background?
          setShowWarning(true)
          setSeconds(0)
          return
        }

        timeoutRef.current = setTimeout(
          () => {
            if (isOfficeUI()) {
              // If we're running in Outlook keep the session alive
              authService.refreshToken()
            } else {
              setShowWarning(true)
              setSeconds(SECONDS_UNTIL_LOGOUT)
            }
          },
          Math.min(
            (secondsRemaining - SECONDS_UNTIL_LOGOUT) * 1000,
            MAX_INTEGER_VALUE,
          ),
        )
        return
      }

      return setShowWarning(false)
    })
  })

  useEffect(() => {
    if (isShowWarning) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [isShowWarning])

  const renderTimeRemaining = (seconds: number) => {
    if (seconds > 60) {
      const minutes = Math.ceil(seconds / 60)
      return minutes + ' minute' + (minutes > 1 ? 's' : '')
    }
    return seconds + ' seconds'
  }

  const content = () => {
    if (seconds <= 0) {
      return (
        <>
          <h2>You have been logged out</h2>
          <p>
            You have been logged out due to inactivity.
            <br />
            Refresh or return to the sign in screen.
          </p>
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              window.location.reload()
            }}
          >
            Login
          </Button>
        </>
      )
    }
    return (
      <>
        <h2>You will be logged out soon</h2>
        <p>
          You will be automatically logged out in {renderTimeRemaining(seconds)}{' '}
          due to inactivity.
        </p>
        <Button
          variant="primary"
          size="lg"
          onClick={async () => {
            await authService.refreshToken()
            setShowWarning(false)
          }}
        >
          Stay Logged In
        </Button>
      </>
    )
  }

  if (isShowWarning) {
    return (
      <OverlayCard className="mt-6" variant="dark">
        {content()}
      </OverlayCard>
    )
  }

  return null
}

export default LogoutWarning
