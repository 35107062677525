import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@rq-ratings/pro-solid-svg-icons'
import { Notyf } from 'notyf'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

export default React.createContext(
  new Notyf({
    duration: 2500,
    position: {
      x: 'right',
      y: 'top',
    },
    types: [
      {
        type: 'default',
        backgroundColor: '#3B7DDD',
        icon: {
          className: 'notyf__icon--success',
          tagName: 'i',
        },
      },
      {
        type: 'info',
        backgroundColor: '#3B7DDD',
        icon: renderToStaticMarkup(<FontAwesomeIcon icon={faCircleInfo} />),
      },
      {
        type: 'success',
        backgroundColor: '#28a745',
        icon: {
          className: 'notyf__icon--success',
          tagName: 'i',
        },
      },
      {
        type: 'warning',
        backgroundColor: '#ffc107',
        icon: {
          className: 'notyf__icon--error',
          tagName: 'i',
        },
      },
      {
        type: 'danger',
        backgroundColor: '#dc3545',
        icon: {
          className: 'notyf__icon--error',
          tagName: 'i',
        },
      },
    ],
  }),
)
