import React from 'react'
import { Nav, Navbar as BootstrapNavbar } from 'react-bootstrap'

import useSidebar from '../../../../../hooks/useSidebar'
import NavbarMenu from './NavbarMenu'

const Navbar: React.FC = () => {
  const { isOpen, setIsOpen } = useSidebar()

  return (
    <>
      <BootstrapNavbar variant="light" expand className="navbar-bg">
        <span
          className="sidebar-toggle d-flex"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <BootstrapNavbar.Collapse>
          <Nav className="navbar-align">
            <NavbarMenu />
          </Nav>
        </BootstrapNavbar.Collapse>
      </BootstrapNavbar>
    </>
  )
}

export default Navbar
