import { useMutation } from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import invariant from 'tiny-invariant'
import * as yup from 'yup'

import { ClientSelectOption } from '../../../../components/select/SelectClient'
import useAppSelector from '../../../../hooks/useAppSelector'
import { ERROR_MESSAGES } from '../../../../lib/constants'
import { isConstraintViolationListError } from '../../../../lib/helpers/helperFunctions'
import { yupSchemas } from '../../../../lib/helpers/yupSchemas'
import clientService from '../../../../lib/services/clientService'
import constraintViolationService from '../../../../lib/services/constraintViolationService'
import { selectClientDetails } from '../../../../redux/slices/referClient'
import {
  CreateClientForReferralRequest,
  UpdateClientForReferralRequest,
} from '../../../../types/requests/clients'
import { ClientDetailsInput, ClientDetailsInputFields } from './constants'

export function buildInitialValues(
  previousInput?: ClientDetailsInput,
): ClientDetailsInput {
  const expectedCommissionAmount =
    typeof previousInput?.expectedCommissionAmount === 'number'
      ? previousInput.expectedCommissionAmount
      : undefined

  return {
    firstName: previousInput?.firstName || '',
    lastName: previousInput?.lastName || '',
    email: previousInput?.email || '',
    telephone: previousInput?.telephone || '',
    clientId: previousInput?.clientId || '',
    isExpectingCommission: previousInput?.isExpectingCommission,
    knowsCommissionAmount: previousInput?.knowsCommissionAmount,
    expectedCommissionAmount,
    circumstances: previousInput?.circumstances || '',
    reason: previousInput?.reason || '',
  }
}

export function getValidationSchema(
  shouldJustifyReasonForIntroduction: boolean,
  shouldShowCommissionFeeQuestion: boolean,
  shouldShowIsExpectingCommissionFollowUpQuestions: boolean,
) {
  return yup.object().shape({
    [ClientDetailsInputFields.firstName]: yup
      .string()
      .required("The client's first name is required"),
    [ClientDetailsInputFields.lastName]: yup
      .string()
      .required("The client's last name is required"),
    [ClientDetailsInputFields.email]: yup
      .string()
      .email('Must be a valid email')
      .required("The client's email is required"),
    [ClientDetailsInputFields.telephone]: yup
      .string()
      .required("The client's telephone is required"),
    [ClientDetailsInputFields.clientId]: yup.string().optional(),
    [ClientDetailsInputFields.isExpectingCommission]: yup
      .boolean()
      .when('firstName', (_firstName, schema) =>
        shouldShowCommissionFeeQuestion
          ? schema.required(ERROR_MESSAGES.required)
          : schema,
      ),
    [ClientDetailsInputFields.knowsCommissionAmount]: yup
      .boolean()
      .when(ClientDetailsInputFields.isExpectingCommission, {
        is: (val: boolean | undefined) => {
          return (
            val === true && shouldShowIsExpectingCommissionFollowUpQuestions
          )
        },
        then: (schema) => schema.required(ERROR_MESSAGES.required),
        otherwise: (schema) => schema.nullable(),
      }),
    [ClientDetailsInputFields.expectedCommissionAmount]: yup
      .mixed()
      .test(
        'is-valid-money-amount-or-negative-one',
        'Must be a valid money amount or Unsure',
        (value) => {
          const validMoneySchema = yupSchemas.validMoneyAmount()
          return validMoneySchema.isValidSync(value) || value === -1
        },
      )
      .when(ClientDetailsInputFields.knowsCommissionAmount, {
        is: (val: boolean | undefined) => {
          return (
            val === true && shouldShowIsExpectingCommissionFollowUpQuestions
          )
        },
        then: (schema) => schema.required(ERROR_MESSAGES.required),
        otherwise: (schema) => schema.nullable(),
      }),
    [ClientDetailsInputFields.circumstances]: yup
      .string()
      .required("Please tell us a bit about your client's situation"),
    [ClientDetailsInputFields.reason]: yup
      .string()
      .when('firstName', (_firstName, schema) =>
        shouldJustifyReasonForIntroduction
          ? schema.required('Please provide a reason for this introduction')
          : schema,
      ),
  })
}

export function useCreateClientMutation() {
  const clientDetails = useAppSelector(selectClientDetails)

  return useMutation({
    mutationFn: (_formikHelpers: FormikHelpers<ClientDetailsInput>) => {
      invariant(clientDetails, 'Expected clientDetails to be set')
      return clientService.createClientForReferral(buildRequest(clientDetails))
    },
    onError: (error, formikHelpers) => {
      if (isConstraintViolationListError(error)) {
        const formattedErrors = constraintViolationService.formatErrors(error)
        formikHelpers.setErrors(formattedErrors)
      }
    },
  })

  function buildRequest(
    input: ClientDetailsInput,
  ): CreateClientForReferralRequest {
    return {
      firstName: input.firstName,
      lastName: input.lastName,
      email: input.email,
      telephone: input.telephone || '',
      clientId: input.clientId || '',
    }
  }
}

export function getPatchedFields(
  client: ClientSelectOption | null,
  formValues?: ClientDetailsInput,
): Array<keyof UpdateClientForReferralRequest> {
  const fields: Array<keyof UpdateClientForReferralRequest> = []

  // If we don't have a client, then no fields have been patched
  if (!client || !formValues) {
    return fields
  }

  if (!client.telephone && !!formValues.telephone) {
    fields.push('telephone')
  }

  if (!client.clientId && !!formValues.clientId) {
    fields.push('clientId')
  }

  return fields
}
