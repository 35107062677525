import invariant from 'tiny-invariant'

import { CompanyTypeCode } from '../../types/misc'
import { CompanyTypeItem } from '../../types/responses/common-data'

class CompanyTypeService {
  getCompanyTypeByCode(
    companyTypes: CompanyTypeItem[],
    companyTypeCode: CompanyTypeCode,
  ): CompanyTypeItem {
    const companyType = companyTypes.find(
      (companyType) => companyType.code === companyTypeCode,
    )

    invariant(
      companyType,
      `Company type with code '${companyTypeCode}' not found`,
    )

    return companyType
  }
}

const companyTypeService = new CompanyTypeService()

export default companyTypeService
