import { FallbackRender } from '@sentry/react'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useMount } from 'react-use'

import { useShowSentryReportDialog } from '../../../../hooks/useShowSentryReportDialog'
import ErrorMessage from '../../../misc/ErrorMessage'

interface Props {
  error: Parameters<FallbackRender>[0]
  fallbackComponent?: React.ReactNode
}

const EmbeddedErrorFallback: React.FC<Props> = ({
  error,
  fallbackComponent,
}) => {
  const showSentryReportDialog = useShowSentryReportDialog()

  useMount(() => {
    showSentryReportDialog(error.eventId)
  })

  if (fallbackComponent) {
    return fallbackComponent
  }

  return (
    <Container>
      <Row>
        <Col lg={8}>
          <Card>
            <Card.Body>
              <ErrorMessage />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default EmbeddedErrorFallback
