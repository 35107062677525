import posthog from 'posthog-js'

import { SessionMetadataItem } from '../../types/responses/session-metadata'
import { POSTHOG_EVENT } from '../constants'
import { isStagingOrProduction } from '../helpers/helperFunctions'

class PosthogService {
  init() {
    if (!this.isEnabled()) {
      return
    }

    posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
      api_host: 'https://ph.rqratings.com',
      autocapture: window.location.hostname !== 'localhost',
      disable_session_recording: window.location.hostname === 'localhost',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    })
  }

  isEnabled() {
    return isStagingOrProduction()
  }

  identifyUser(sessionMetadata: SessionMetadataItem) {
    if (!this.isEnabled()) {
      return
    }

    const { currentUser, currentCompany, currentCompanyUser } = sessionMetadata

    if (currentUser && !currentUser.impersonated) {
      posthog.identify(currentUser.keycloakId, {
        id: currentUser.id,
        email: currentUser.email,
        name: currentUser.fullName,
        company: currentCompany?.presentationName,
        currentCompanyUserId: currentCompanyUser?.id,
      })

      posthog.capture(POSTHOG_EVENT.login)
    }
  }
}

const posthogService = new PosthogService()

export default posthogService
