import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entries } from 'type-fest'

import { OnboardingChecklistItem } from '../../types/responses/onboarding-checklists'
import { RootState } from '../store'

export interface OnboardingChecklistState {
  checklist: OnboardingChecklistItem | null
}

const INITIAL_STATE: OnboardingChecklistState = {
  checklist: null,
}

export type OnboardingChecklistStep =
  | 'inviteTeam'
  | 'exploreCompass'
  | 'addRelationship'
  | 'uploadHistoricalReferrals'
  | 'makeReferral'

const onboardingChecklist = createSlice({
  name: 'onboardingChecklist',
  initialState: INITIAL_STATE,
  reducers: {
    setOnboardingChecklist(
      state,
      action: PayloadAction<OnboardingChecklistState['checklist']>,
    ) {
      state.checklist = action.payload
    },
  },
})

export const { setOnboardingChecklist } = onboardingChecklist.actions

interface OnboardingChecklistStepDetails {
  name: OnboardingChecklistStep
  isCompleted: boolean
}

export const selectOnboardingChecklistSlice = (state: RootState) =>
  state.onboardingChecklist

export const selectOnboardingChecklist = createSelector(
  selectOnboardingChecklistSlice,
  (state) => state.checklist,
)

export const selectOnboardingChecklistSteps = createSelector(
  selectOnboardingChecklist,
  (checklist): OnboardingChecklistStepDetails[] => {
    if (!checklist) {
      return []
    }

    return (
      Object.entries(checklist.steps) as Entries<typeof checklist.steps>
    ).map(([stepName, isCompleted]) => ({
      name: stepName,
      isCompleted,
    }))
  },
)

export const selectActiveOnboardingChecklistStep = createSelector(
  selectOnboardingChecklistSteps,
  (steps): OnboardingChecklistStepDetails | undefined => {
    return steps.find((step) => !step.isCompleted)
  },
)

export const selectIsLastOnboardingChecklistStep = createSelector(
  selectActiveOnboardingChecklistStep,
  selectOnboardingChecklistSteps,
  (activeStep, checklist) => {
    const lastStep = checklist[checklist.length - 1]
    return activeStep?.name === lastStep.name
  },
)

export const selectPreviousOnboardingChecklistStep = createSelector(
  selectOnboardingChecklistSteps,
  (steps): OnboardingChecklistStepDetails | undefined => {
    const completedSteps = steps.filter((step) => step.isCompleted)
    return completedSteps[completedSteps.length - 1]
  },
)

export const selectIsOnboardingChecklistIncomplete = createSelector(
  selectOnboardingChecklist,
  (checklist) => {
    return !!checklist && checklist.isComplete === false
  },
)

export const selectIsOnboardingChecklistComplete = createSelector(
  selectOnboardingChecklist,
  (checklist) => !!checklist && checklist.isComplete === true,
)

export const onboardingChecklistReducer = onboardingChecklist.reducer

export const ONBOARDING_CHECKLIST_STEP: Record<
  OnboardingChecklistStep,
  OnboardingChecklistStep
> = {
  inviteTeam: 'inviteTeam',
  exploreCompass: 'exploreCompass',
  addRelationship: 'addRelationship',
  uploadHistoricalReferrals: 'uploadHistoricalReferrals',
  makeReferral: 'makeReferral',
}
