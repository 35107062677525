import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { useAuth } from '../../../../../hooks/useAuth'
import CompleteRegistrationPopUpButton from '../../../../misc/CompleteRegistrationPopUpButton/CompleteRegistrationPopUpButton'
import { SidebarPage } from './Sidebar'
import SidebarNavSection from './SidebarNavSection'

interface Props {
  items: { title?: string; pages: SidebarPage[] }[]
}

const SidebarNav: React.FC<Props> = ({ items }) => {
  const { currentCompany } = useAuth()

  return (
    <ul className="sidebar-nav flex-grow-1">
      {currentCompany && (
        <CompleteRegistrationPopUpButton
          className="btn btn-outline-warning w-100"
          containerClassName="sidebar-header pt-1 mb-3"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
          Complete Registration
        </CompleteRegistrationPopUpButton>
      )}

      {items && (
        <>
          {items.map((item, index) => (
            <SidebarNavSection
              key={index}
              pages={item.pages}
              title={item.title}
            />
          ))}
        </>
      )}
    </ul>
  )
}

export default SidebarNav
