import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'

import {
  COMPANY_TYPE_CODE,
  REFERRAL_TYPE,
  REFERRAL_TYPE_OPTION,
  SERVICE_AREA_ID_WILL,
} from '../../lib/constants'
import {
  getReadableCompanyType,
  isValidCompanyTypeCode,
} from '../../lib/helpers/companyHelpers'
import { getNextStep, getPreviousStep } from '../../lib/helpers/helperFunctions'
import {
  getQueryParam,
  REFER_CLIENT_PARAMS,
} from '../../lib/helpers/routeHelpers'
import companyTypeService from '../../lib/services/companyTypeService'
import referClientService from '../../lib/services/referClientService'
import { ClientDetailsInput } from '../../pages/referClient/steps/4_ClientDetails/constants'
import { buildInitialValues } from '../../pages/referClient/steps/4_ClientDetails/helpers'
import {
  CompanyTypeCode,
  ReferralTypeOption,
  SerializableFile,
} from '../../types/misc'
import { ClientItem } from '../../types/responses/clients'
import {
  CompanyDetailsItem,
  ReferralFirmIndividualsCollection,
} from '../../types/responses/companies'
import { CompassReportCollectionItem } from '../../types/responses/compass-reports'
import { NewReferralMetadataItem } from '../../types/responses/referrals'
import { RootState } from '../store'
import {
  getServiceAreasByIds,
  selectCompanyTypes,
  selectServiceAreas,
} from './commonData'

export interface ReferClientState {
  currentStep: ReferClientStep
  metadata?: NewReferralMetadataItem
  companyTypeCode?: CompanyTypeCode
  referralFirm?: CompanyDetailsItem
  referralFirmIndividuals?: ReferralFirmIndividual[]
  serviceAreaIds: number[]
  client?: ClientItem
  clientDetails?: ClientDetailsInput
  compassReport?: CompassReportCollectionItem
  consentType?: ReferralConsentType
  referralType?: ReferralTypeOption
  userEmailMessage?: string
  attachment?: SerializableFile | null
  requestCallbackForm?: {
    callbackTimes?: string[]
    callbackTelephone?: string
  }
  commissionJustification?: string
  hasEditedCommissionJustification?: boolean
  hasSentReviewAgreementReminder: boolean
  isShowingRequestApprovalModal?: boolean
  approvalRequestMessage?: string
}

export type ReferralFirmIndividual =
  ReferralFirmIndividualsCollection['hydra:member'][number]

export type ReferClientStep =
  | 'selectAdviser'
  | 'clientConsent'
  | 'clientDetails'
  | 'commercialAgreement'
  | 'selectReferralMethod'
  | 'confirmPcoReferral'

export const REFER_CLIENT_STEPS: Record<ReferClientStep, ReferClientStep> = {
  selectAdviser: 'selectAdviser',
  commercialAgreement: 'commercialAgreement',
  clientConsent: 'clientConsent',
  clientDetails: 'clientDetails',
  selectReferralMethod: 'selectReferralMethod',
  confirmPcoReferral: 'confirmPcoReferral',
}

export type ReferralConsentType =
  | 'YES_E-SIGNATURE'
  | 'NO_CLIENT_LETTER_SIGNED'
  | 'NO_ADVISOR_WILL_SHARE'
  | 'NA'

export const REFERRAL_CONSENT_TYPE = {
  yesESignature: 'YES_E-SIGNATURE',
  noClientLetterAlreadySigned: 'NO_CLIENT_LETTER_SIGNED',
  noAdviserWillShare: 'NO_ADVISOR_WILL_SHARE',
  na: 'NA',
} satisfies Record<string, ReferralConsentType>

const INITIAL_STATE: ReferClientState = {
  currentStep: REFER_CLIENT_STEPS.selectAdviser,
  serviceAreaIds: [],
  hasSentReviewAgreementReminder: false,
}

const sliceName = 'referClient'

export function buildInitialReferClientState(): ReferClientState {
  return {
    ...INITIAL_STATE,

    companyTypeCode: getQueryParam<CompanyTypeCode | undefined>({
      name: REFER_CLIENT_PARAMS.companyTypeCode,
      transform: (value) => (isValidCompanyTypeCode(value) ? value : undefined),
    }),

    serviceAreaIds: getQueryParam<Array<number>>({
      name: REFER_CLIENT_PARAMS.serviceAreaIds,
      transform: (value) => {
        // Ignore invalid values
        if (!(typeof value === 'string')) {
          return []
        }

        // Handle multiple service area IDs
        if (value.includes(',')) {
          return value.split(',').map((id) => Number(id))
        }

        // Handle single service area ID
        return [Number(value)]
      },
    }),

    consentType: getQueryParam<ReferralConsentType | undefined>({
      name: REFER_CLIENT_PARAMS.consentType,
      transform: (value) => {
        function isValidConsentType(
          value: unknown,
        ): value is ReferralConsentType {
          return (
            typeof value === 'string' &&
            Object.keys(REFERRAL_CONSENT_TYPE).includes(value)
          )
        }

        return isValidConsentType(value) ? value : undefined
      },
    }),
  }
}

const referClient = createSlice({
  name: sliceName,
  initialState: buildInitialReferClientState,
  reducers: {
    initialiseReferClient() {
      return buildInitialReferClientState()
    },

    goToStep(state, action: PayloadAction<ReferClientStep>) {
      state.currentStep = action.payload
    },

    setReferClientMetadata: (
      state,
      action: PayloadAction<ReferClientState['metadata']>,
    ) => {
      state.metadata = action.payload
    },

    setCompanyTypeCode: (
      state,
      action: PayloadAction<ReferClientState['companyTypeCode']>,
    ) => {
      state.companyTypeCode = action.payload
      state.serviceAreaIds = []
    },

    setReferralFirm: (
      state,
      action: PayloadAction<ReferClientState['referralFirm']>,
    ) => {
      state.referralFirm = action.payload
    },

    setReferralFirmIndividuals: (
      state,
      action: PayloadAction<ReferClientState['referralFirmIndividuals']>,
    ) => {
      state.referralFirmIndividuals = action.payload
    },

    setServiceAreaIds: (
      state,
      action: PayloadAction<ReferClientState['serviceAreaIds']>,
    ) => {
      state.serviceAreaIds = action.payload
    },

    setClientDetails: (
      state,
      action: PayloadAction<ReferClientState['clientDetails']>,
    ) => {
      state.clientDetails = action.payload
    },

    setClientCircumstances: (state, action: PayloadAction<string>) => {
      if (!state.clientDetails) {
        throw new Error(
          'Tried setting client circumstances without state.clientDetails being set',
        )
      }

      state.clientDetails.circumstances = action.payload
    },

    setClient: (state, action: PayloadAction<ClientItem>) => {
      const previousClient = state.client
      const selectedClient = action.payload

      state.client = selectedClient

      state.clientDetails = {
        ...state.clientDetails,
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        email: selectedClient.email,
        telephone: selectedClient.telephone || '',
        clientId: selectedClient.clientId || '',
        circumstances: state.clientDetails?.circumstances || '',
      }

      // Clear Compass report if the client has changed
      if (previousClient && selectedClient.id !== previousClient.id) {
        state.compassReport = undefined
      }
    },

    clearClient: (state) => {
      state.client = undefined
      state.compassReport = INITIAL_STATE.compassReport
      state.clientDetails = {
        ...buildInitialValues(INITIAL_STATE.clientDetails),
        circumstances: state.clientDetails?.circumstances || '', // Preserve circumstances
      }
    },

    setCompassReport: (
      state,
      action: PayloadAction<ReferClientState['compassReport']>,
    ) => {
      state.compassReport = action.payload
    },

    clearCompassReportId: (state) => {
      state.compassReport = undefined
    },

    clearCommissionQuestions: (state) => {
      if (state.clientDetails) {
        state.clientDetails.isExpectingCommission = undefined
        state.clientDetails.knowsCommissionAmount = undefined
        state.clientDetails.expectedCommissionAmount = undefined
      }
    },

    handleReferralFirmChange: (state) => {
      if (state.clientDetails) {
        state.clientDetails.isExpectingCommission = undefined
        state.clientDetails.knowsCommissionAmount = undefined
        state.clientDetails.expectedCommissionAmount = undefined
      }

      state.metadata = undefined
      state.consentType = undefined
      state.referralType = undefined
      state.referralFirmIndividuals = undefined
      state.hasSentReviewAgreementReminder = false
    },

    setConsentType: (
      state,
      action: PayloadAction<ReferClientState['consentType']>,
    ) => {
      state.consentType = action.payload
    },

    setReferralType: (
      state,
      action: PayloadAction<ReferClientState['referralType']>,
    ) => {
      state.referralType = action.payload
    },

    clearReferralType(state) {
      state.referralType = INITIAL_STATE.referralType
    },

    setUserEmailMessage: (
      state,
      action: PayloadAction<ReferClientState['userEmailMessage']>,
    ) => {
      state.userEmailMessage = action.payload
    },

    setApprovalRequestMessage: (
      state,
      action: PayloadAction<ReferClientState['approvalRequestMessage']>,
    ) => {
      state.approvalRequestMessage = action.payload
    },

    setAttachment: (
      state,
      action: PayloadAction<ReferClientState['attachment']>,
    ) => {
      state.attachment = action.payload
    },

    setRequestCallbackForm: (
      state,
      action: PayloadAction<ReferClientState['requestCallbackForm']>,
    ) => {
      state.requestCallbackForm = action.payload
    },

    setHasSentReviewAgreementReminder: (
      state,
      action: PayloadAction<ReferClientState['hasSentReviewAgreementReminder']>,
    ) => {
      state.hasSentReviewAgreementReminder = action.payload
    },

    setCommissionJustification: (
      state,
      action: PayloadAction<ReferClientState['commissionJustification']>,
    ) => {
      state.commissionJustification = action.payload
    },

    setHasEditedCommissionJustification: (
      state,
      action: PayloadAction<
        ReferClientState['hasEditedCommissionJustification']
      >,
    ) => {
      state.hasEditedCommissionJustification = action.payload
    },

    showRequestApprovalModal: (state) => {
      state.isShowingRequestApprovalModal = true
    },

    hideRequestApprovalModal: (state) => {
      state.isShowingRequestApprovalModal = false
    },
  },
})

export const referClientReducer = referClient.reducer

export const {
  initialiseReferClient,
  goToStep,
  setReferClientMetadata,
  setCompanyTypeCode,
  setReferralFirm,
  setReferralFirmIndividuals,
  setServiceAreaIds,
  setClient,
  setClientDetails,
  setClientCircumstances,
  clearClient,
  setCompassReport,
  clearCompassReportId,
  clearCommissionQuestions,
  handleReferralFirmChange,
  setConsentType,
  setReferralType,
  clearReferralType,
  setUserEmailMessage,
  setAttachment,
  setRequestCallbackForm,
  setHasSentReviewAgreementReminder,
  setCommissionJustification,
  setHasEditedCommissionJustification,
  setApprovalRequestMessage,
  showRequestApprovalModal,
  hideRequestApprovalModal,
} = referClient.actions

export const removeReferralFirmSelection = createAsyncThunk(
  `${sliceName}/removeReferralFirmSelection`,
  async (_, { dispatch }) => {
    dispatch(handleReferralFirmChange())
    dispatch(setReferralFirm(undefined))

    const url = new URL(window.location.href)
    const params = url.searchParams
    params.delete(REFER_CLIENT_PARAMS.referralFirmId)
    window.history.pushState({}, '', url)
  },
)

const PCO_REFER_CLIENT_STEP_NAMES: ReferClientStep[] = [
  REFER_CLIENT_STEPS.selectAdviser,
  REFER_CLIENT_STEPS.clientDetails,
  REFER_CLIENT_STEPS.confirmPcoReferral,
]

const NORMAL_REFER_CLIENT_STEP_NAMES: ReferClientStep[] = [
  REFER_CLIENT_STEPS.selectAdviser,
  REFER_CLIENT_STEPS.commercialAgreement,
  REFER_CLIENT_STEPS.clientConsent,
  REFER_CLIENT_STEPS.clientDetails,
  REFER_CLIENT_STEPS.selectReferralMethod,
]

export type ReferClientSteps = Array<keyof typeof REFER_CLIENT_STEPS>

export const selectReferClient = (state: RootState) => state.referClient

export const selectReferralFirm = createSelector(
  selectReferClient,
  (state) => state.referralFirm,
)

export const selectReferralFirmOrFail = createSelector(
  selectReferralFirm,
  (referralFirm) => {
    invariant(referralFirm, 'Expected referralFirm to be set')

    return referralFirm
  },
)

export const selectCurrentStep = createSelector(
  selectReferClient,
  (state) => state.currentStep,
)

export const selectServiceAreaIds = createSelector(
  selectReferClient,
  (state) => state.serviceAreaIds,
)

export const selectIsPcoReferral = createSelector(
  selectServiceAreaIds,
  selectReferralFirm,
  (serviceAreaIds, referralFirm): boolean =>
    serviceAreaIds.includes(SERVICE_AREA_ID_WILL) && !!referralFirm?.isPco,
)

export const selectSteps = createSelector(
  selectIsPcoReferral,
  (isPcoReferral) => {
    return isPcoReferral
      ? PCO_REFER_CLIENT_STEP_NAMES
      : NORMAL_REFER_CLIENT_STEP_NAMES
  },
)

export const selectNextStep = createSelector(
  selectSteps,
  selectCurrentStep,
  (steps, currentStep) => {
    return getNextStep<ReferClientSteps>(steps, currentStep)
  },
)

export const selectPreviousStep = createSelector(
  selectSteps,
  selectCurrentStep,
  (steps, currentStep) => {
    return getPreviousStep<ReferClientSteps>(steps, currentStep)
  },
)

export const selectReferClientMetadata = createSelector(
  selectReferClient,
  (state) => state.metadata,
)

export const selectReferClientMetadataOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.metadata, 'Expected metadata to be set')

    return state.metadata
  },
)

export const selectCompanyTypeCode = createSelector(
  selectReferClient,
  (state) => state.companyTypeCode,
)

export const selectSelectedCompanyType = createSelector(
  selectCompanyTypes,
  selectCompanyTypeCode,
  (companyTypes, selectedCompanyTypeCode) =>
    selectedCompanyTypeCode
      ? companyTypeService.getCompanyTypeByCode(
          companyTypes,
          selectedCompanyTypeCode,
        )
      : null,
)

export const selectReadableCompanyType = createSelector(
  selectCompanyTypes,
  selectCompanyTypeCode,
  (companyTypes, selectedCompanyTypeCode) =>
    selectedCompanyTypeCode
      ? getReadableCompanyType(companyTypes, selectedCompanyTypeCode)
      : undefined,
)

export const selectIsReferringToIfa = createSelector(
  selectCompanyTypeCode,
  (companyTypeCode) => companyTypeCode === COMPANY_TYPE_CODE.ifa,
)

export const selectReferralFirmIndividuals = createSelector(
  selectReferClient,
  (state) => state.referralFirmIndividuals,
)

export const selectSelectedServiceAreas = createSelector(
  selectServiceAreas,
  selectServiceAreaIds,
  (serviceAreas, serviceAreaIds) => {
    return getServiceAreasByIds(serviceAreas, serviceAreaIds)
  },
)

export const selectClient = createSelector(
  selectReferClient,
  (state) => state.client,
)

export const selectClientOrFail = createSelector(selectClient, (client) => {
  invariant(client, 'Expected client to be set')

  return client
})

export const selectClientDetails = createSelector(
  selectReferClient,
  (state) => state.clientDetails,
)

export const selectClientDetailsOrFail = createSelector(
  selectReferClient,
  (state) => {
    invariant(state.clientDetails, 'Expected state.clientDetails to be set')

    return state.clientDetails
  },
)

export const selectCompassReport = createSelector(
  selectReferClient,
  (state) => state.compassReport,
)

export const selectConsentType = createSelector(
  selectReferClient,
  (state) => state.consentType,
)

export const selectNeedsClientConsent = createSelector(
  selectReferClient,
  (state) => referClientService.needsClientConsent(state.consentType),
)

export const selectReferralTypeOption = createSelector(
  selectReferClient,
  (state) => state.referralType,
)

export const selectIsNormalReferralType = createSelector(
  selectReferralTypeOption,
  (referralTypeOption) =>
    referClientService.isNormalReferralType(referralTypeOption),
)

export const selectIsBookingCallForClient = createSelector(
  selectReferralTypeOption,
  (referralTypeOption) =>
    referClientService.isBookCallForClientReferralType(referralTypeOption),
)

export const selectIsRequestingReferralCallbackForClient = createSelector(
  selectIsBookingCallForClient,
  selectReferClientMetadata,
  (isBookingCallForClient, metadata) => {
    return isBookingCallForClient && !metadata?.toCompany.scheduleClientCallUrl
  },
)

export const selectIsSchedulingMeetingForClient = createSelector(
  selectIsBookingCallForClient,
  selectReferClientMetadata,
  (isBookingCallForClient, metadata) => {
    return isBookingCallForClient && metadata?.toCompany.scheduleClientCallUrl
  },
)
export const selectNormalizedReferralType = createSelector(
  selectReferralTypeOption,
  (referralType) => referClientService.normalizeReferralType(referralType),
)

export const selectIsDirectReferral = createSelector(
  selectReferralTypeOption,
  (referralType) => {
    return (
      referralType === REFERRAL_TYPE.direct ||
      referralType === REFERRAL_TYPE.indirect
    )
  },
)

export const selectShouldCcClientOnInitialEmail = createSelector(
  selectReferralTypeOption,
  (referralType) => {
    return referralType === REFERRAL_TYPE_OPTION.directCc
  },
)

export const selectUserEmailMessage = createSelector(
  selectReferClient,
  (referClient) => referClient.userEmailMessage,
)

export const selectCanReferToIndividuals = createSelector(
  selectIsPcoReferral,
  (isPcoReferral): boolean => isPcoReferral === false,
)

export const selectAttachment = createSelector(
  selectReferClient,
  (referClient) => referClient.attachment,
)

export const selectRequestCallbackForm = createSelector(
  selectReferClient,
  (referClient) => referClient.requestCallbackForm,
)

export const selectHasSentReviewAgreementReminder = createSelector(
  selectReferClient,
  (referClient) => referClient.hasSentReviewAgreementReminder,
)

export const selectCommissionJustification = createSelector(
  selectReferClient,
  (referClient) => referClient.commissionJustification,
)

export const selectHasEditedCommissionJustification = createSelector(
  selectReferClient,
  (referClient) => referClient.hasEditedCommissionJustification,
)

export const selectApprovalRequestMessage = createSelector(
  selectReferClient,
  (referClient) => referClient.approvalRequestMessage,
)

export const selectIsShowingRequestApprovalModal = createSelector(
  selectReferClient,
  (referClient) => referClient.isShowingRequestApprovalModal,
)
