import React from 'react'

import Overlay from './Overlay'

interface Props {
  children: React.ReactNode
  className?: string
  variant: 'dark' | 'body-bg' | 'white'
}
const OverlayCard: React.FC<Props> = ({ children, className, variant }) => {
  return (
    <Overlay variant={variant}>
      <div
        className={`bg-white shadow-lg rounded-lg mx-auto p-4 ${className}`}
        style={{ width: 'calc(100% - 2rem)', maxWidth: '700px' }}
      >
        {children}
      </div>
    </Overlay>
  )
}

export default OverlayCard
