import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { QUERY_KEYS } from '../lib/queryKeys'
import onboardingChecklistService from '../lib/services/onboardingChecklistService'

export function useOnboardingChecklistQueryOrFail(checklistId?: number) {
  return useQuery({
    queryKey: QUERY_KEYS.onboardingChecklist(),
    queryFn: () => {
      invariant(checklistId, 'Expected checklistId to be defined')

      return onboardingChecklistService.getChecklist(checklistId)
    },
    enabled: !!checklistId,
  })
}
