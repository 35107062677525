import { useFormikContext } from 'formik'
import React from 'react'

import useAppSelector from '../../../../../../hooks/useAppSelector'
import { selectQuestions } from '../../../../../../redux/slices/compass'
import CheckboxInput from '../../../../../form/CheckboxInput'
import FieldArrayInput from '../../../../../form/FieldArrayInput'
import FormGroup from '../../../../../form/FormGroup'
import MoneyInput from '../../../../../form/MoneyInput'
import MonthYearInput from '../../../../../form/MonthYearInput'
import RadioOptionsInputVertical from '../../../../../form/RadioOptionsInputVertical'
import { OPTIONS } from '../../../utils/options'
import { DefinedBenefitInput, PersonalAssetsInput } from '../../../utils/types'
import { useCompassMode } from '../../../utils/useCompassMode'
import { makeDefinedBenefitPlaceholder } from '../helpers'

interface Props {
  name: string
  items: DefinedBenefitInput[]
  onRemove: () => void
}

export const DefinedBenefitInputFields: Record<
  keyof DefinedBenefitInput,
  string
> = {
  defined_benefit_commence_month: 'defined_benefit_commence_month',
  defined_benefit_commence_year: 'defined_benefit_commence_year',
  defined_benefit_status: 'defined_benefit_status',
  defined_benefit_annual_gross_benefit: 'defined_benefit_annual_gross_benefit',
  defined_benefit_is_unknown: 'defined_benefit_is_unknown',
}

const DefinedBenefitsFieldArray: React.FC<Props> = ({
  name,
  items,
  onRemove,
}) => {
  const questions = useAppSelector(selectQuestions)
  const { setFieldValue, getFieldMeta } =
    useFormikContext<PersonalAssetsInput>()
  const { isReadOnlyMode } = useCompassMode()

  return (
    <FieldArrayInput
      name={name}
      items={items}
      itemName="Defined Benefit Pension"
      defaultOption={makeDefinedBenefitPlaceholder()}
      isDisabled={isReadOnlyMode}
      renderItem={({ index }) => {
        function makeFieldName(fieldName: string) {
          return `${name}[${index}][${fieldName}]`
        }

        const annualGrossBenefitFieldName = makeFieldName(
          DefinedBenefitInputFields.defined_benefit_annual_gross_benefit,
        )

        const isDefinedBenefitUnknownFieldName = makeFieldName(
          DefinedBenefitInputFields.defined_benefit_is_unknown,
        ) as keyof PersonalAssetsInput

        const isDefinedBenefitUnknown =
          getFieldMeta(isDefinedBenefitUnknownFieldName).value === true

        return (
          <>
            <FormGroup>
              <MonthYearInput
                label={questions.personalAssets.definedBenefitCommenceDate}
                monthName={makeFieldName(
                  DefinedBenefitInputFields.defined_benefit_commence_month,
                )}
                yearName={makeFieldName(
                  DefinedBenefitInputFields.defined_benefit_commence_year,
                )}
                type="future"
                isDisabled={isReadOnlyMode}
              />
            </FormGroup>

            <FormGroup>
              <RadioOptionsInputVertical
                label={questions.personalAssets.definedBenefitMemberType}
                name={makeFieldName(
                  DefinedBenefitInputFields.defined_benefit_status,
                )}
                options={OPTIONS.defined_benefit_status}
                variant="compact"
                isDisabled={isReadOnlyMode}
              />
            </FormGroup>

            <FormGroup className="mb-auto pb-2">
              <MoneyInput
                label={
                  questions.personalAssets.definedBenefitAnnualGrossBenefit
                }
                name={annualGrossBenefitFieldName}
                isDisabled={isReadOnlyMode}
                onChange={(value) => {
                  if (value.length > 0) {
                    setFieldValue(isDefinedBenefitUnknownFieldName, false)
                  }
                }}
              />
            </FormGroup>

            <FormGroup className="mb-auto">
              <CheckboxInput
                label={questions.personalAssets.definedBenefitIsUnknown}
                name={isDefinedBenefitUnknownFieldName}
                isDisabled={isReadOnlyMode}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setFieldValue(annualGrossBenefitFieldName, '')
                  }
                }}
              />

              {isDefinedBenefitUnknown && (
                <div className="text-muted text-sm mt-n1">
                  We will assume the income to be £0.
                </div>
              )}
            </FormGroup>
          </>
        )
      }}
      addButtonText=" Add additional Defined Benefit pension"
      onRemove={onRemove}
    />
  )
}

export default DefinedBenefitsFieldArray
