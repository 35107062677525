import { DEFAULT_FINANCIAL_INDEPENDENCE_AGE } from '../../../components/flows/CompassFlow/utils/constants'
import { InterestsInput } from '../../../components/flows/CompassFlow/utils/types'

class CompassInterestsService {
  getFinancialIndependenceAge(interests?: InterestsInput): number {
    if (interests?.financial_independence_age) {
      return interests.financial_independence_age
    }

    return DEFAULT_FINANCIAL_INDEPENDENCE_AGE
  }
}

const compassInterestsService = new CompassInterestsService()

export default compassInterestsService
