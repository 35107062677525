export interface ClientDetailsInput {
  firstName: string
  lastName: string
  email: string
  telephone: string
  clientId?: string
  isExpectingCommission?: boolean
  expectedCommissionAmount?: number
  knowsCommissionAmount?: boolean
  circumstances: string
  reason?: string
}

export const ClientDetailsInputFields: Record<
  keyof ClientDetailsInput,
  keyof ClientDetailsInput
> = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  telephone: 'telephone',
  clientId: 'clientId',
  isExpectingCommission: 'isExpectingCommission',
  expectedCommissionAmount: 'expectedCommissionAmount',
  knowsCommissionAmount: 'knowsCommissionAmount',
  circumstances: 'circumstances',
  reason: 'reason',
}
