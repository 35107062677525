import classNames from 'classnames'
import React from 'react'

type Props = React.ComponentPropsWithoutRef<'button'>

const PlainButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        type="button"
        className={classNames(
          'fs-inherit text-primary PlainButton',
          props.className,
        )}
      />
    )
  },
)

export default PlainButton
