import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { isDevelopment } from '../lib/helpers/helperFunctions'
import { QUERY_KEYS } from '../lib/queryKeys'
import { ROUTES } from '../lib/routes'
import rqCertifiedAssessmentService from '../lib/services/rqCertifiedAssessmentService'
import { selectSessionMetadata } from '../redux/slices/session'
import useAppSelector from './useAppSelector'
import { useIsRoute } from './useIsRoute'

export function useRqCertifiedAssessmentQuery() {
  const sessionMetadata = useAppSelector(selectSessionMetadata)
  const isRoute = useIsRoute()

  const assessmentIri = sessionMetadata?.currentCompany?.rqCertifiedAssessment

  return useQuery({
    queryKey: QUERY_KEYS.rqCertifiedAssessment,
    queryFn: () => {
      invariant(assessmentIri, 'Expected assessmentIri to be set')

      return rqCertifiedAssessmentService.getAssessmentByIri(assessmentIri)
    },
    enabled: !!assessmentIri, // Only run this query if the assessmentIri is set
    staleTime: 2000,
    refetchOnWindowFocus: isRoute(ROUTES.rqCertifiedApplicationStatus),
  })
}

export function useRqCertifiedAssessment() {
  const { data } = useRqCertifiedAssessmentQuery()

  return data
}

export function useRqCertifiedAssessmentOrFail() {
  const assessment = useRqCertifiedAssessment()

  // TODO: Will remove after everyone has had the chance to run the command
  const errorMessage = isDevelopment()
    ? '. Did you run the app:rq-certified-assessment:create command?'
    : ''

  invariant(
    assessment,
    `Expected rqCertifiedAssessmentQuery.data to be set${errorMessage}`,
  )

  return assessment
}
