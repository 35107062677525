import {
  InterestsInput,
  OutflowInput,
  PensionContributionType,
} from '../../utils/types'

export const InterestsInputFields: Record<
  keyof InterestsInput,
  keyof InterestsInput
> = {
  financial_independence_age: 'financial_independence_age',
  interests_options: 'interests_options',
  financial_wellbeing_score: 'financial_wellbeing_score',
  has_additional_outflows: 'has_additional_outflows',
  outflows: 'outflows',
  has_financial_advisor: 'has_financial_advisor',
  financial_advisor: 'financial_advisor',
  duration_with_financial_advisor: 'duration_with_financial_advisor',
  other_issue: 'other_issue',
}

export const OutflowInputFields: Record<keyof OutflowInput, string> = {
  outflow_category: 'outflow_category',
  outflow_category_key: 'outflow_category_key',
  outflow_objective: 'outflow_objective',
  outflow_costs: 'outflow_costs',
  outflow_is_one_off: 'outflow_is_one_off',
  outflow_is_ongoing: 'outflow_is_ongoing',
  outflow_frequency: 'outflow_frequency',
  outflow_start_month: 'outflow_start_month',
  outflow_start_year: 'outflow_start_year',
  outflow_end_month: 'outflow_end_month',
  outflow_end_year: 'outflow_end_year',
}

export const PENSION_CONTRIBUTION_TYPE: Record<
  PensionContributionType,
  PensionContributionType
> = {
  total: 'total',
  share: 'share',
}
