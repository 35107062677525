import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Info } from 'react-feather'

import { getTooltipClassName } from '../../lib/helpers/helperFunctions'
import FieldError from './FieldError'
import FieldLabel, { TooltipHint } from './FieldLabel'
import TextInput from './TextInput'

type Variant = 'spaced' | 'compact'

interface Props {
  name: string
  label?: string | React.ReactNode
  onChange?: (value: string) => void
  options: RadioOption[]
  otherOption?: {
    name: string
    label: string
    placeholder?: string
    onChange?: (newValue: string) => void
  }
  noneOption?: {
    label: string
    value: string
  }
  variant?: Variant
  isDisabled?: boolean
}

type RadioOption = {
  label: string
  value: string
  isOtherOption?: boolean
  tooltipHint?: TooltipHint
}

const RadioOptionsInputVertical: React.FC<Props> = ({
  name,
  label,
  onChange,
  options,
  otherOption,
  noneOption,
  variant = 'compact',
  isDisabled,
}) => {
  const { setFieldValue, submitCount } = useFormikContext()
  const [field, { error }] = useField<string>(name)
  const showError = !!(submitCount > 0 && error)
  const id = `field_${name}`

  function handleChange(option: RadioOption) {
    setFieldValue(name, option.value)

    if (onChange) {
      onChange(option.value)
    }
  }

  function renderOption(option: RadioOption) {
    const id = `field_${name}_${option.value}`
    const isSelected = option.value === field.value
    const { isOtherOption } = option
    const optionLabel = isOtherOption ? otherOption?.label : option.label

    return (
      <OptionWrapper key={option.value} variant={variant}>
        <div className="d-flex align-items-center">
          <label
            key={option.label}
            className={classNames(
              'd-flex cursor-pointer align-items-center',
              variant === 'spaced' ? 'px-3' : 'px-2',
              isDisabled && 'label-disabled',
            )}
          >
            <input
              id={id}
              name={name}
              checked={isSelected}
              type="radio"
              value={option.value}
              className="me-2"
              onChange={() => {
                handleChange(option)
              }}
              disabled={isDisabled}
            />

            {optionLabel}
          </label>

          {option.tooltipHint && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip
                  className={getTooltipClassName(option.tooltipHint.text)}
                >
                  {option.tooltipHint.text}
                </Tooltip>
              }
            >
              <Info className="text-primary" size={15} />
            </OverlayTrigger>
          )}
        </div>

        {otherOption && isOtherOption && isSelected && (
          <div className="px-3">
            <TextInput
              name={otherOption.name}
              placeholder={otherOption.placeholder}
              autoFocus={(value) =>
                typeof value === 'undefined' || value === ''
              }
              onChange={otherOption.onChange}
            />
          </div>
        )}
      </OptionWrapper>
    )
  }

  return (
    <>
      {label && <FieldLabel htmlFor={id}>{label}</FieldLabel>}
      {options.map(renderOption)}
      {noneOption && renderOption(noneOption)}
      {showError && (
        <FieldError className="mt-2" inputId={id}>
          {error}
        </FieldError>
      )}
    </>
  )
}

const OptionWrapper: React.FC<{
  children: React.ReactNode
  className?: string
  variant: Variant
}> = ({ children, className, variant }) => (
  <div
    className={classNames(
      'border-bottom',
      variant === 'spaced' ? 'py-3' : 'py-2',
      className,
    )}
    style={{ borderColor: '#D1D4D7' }}
  >
    {children}
  </div>
)

export default RadioOptionsInputVertical
