import { useMutation, useQueryClient } from '@tanstack/react-query'

import { QUERY_KEYS } from '../lib/queryKeys'
import sentryService from '../lib/services/sentryService'
import stateService from '../lib/services/stateService'
import { StateType } from '../types/misc'
import { CreateStateRequest } from '../types/requests/states'
import { useShowGenericServerError } from './useShowGenericServerError'

type MutationOptions = CreateStateRequest & {
  stateType: StateType
  invalidateStateQuery?: boolean
}

export function useCreateStateMutation() {
  const queryClient = useQueryClient()
  const showGenericServerError = useShowGenericServerError()

  return useMutation({
    mutationFn: (options: MutationOptions) => stateService.createState(options),
    retry: 2,
    onSuccess: async (_response, { invalidateStateQuery = true }) => {
      // Allow the caller to opt out of invalidating the app state query
      if (!invalidateStateQuery) {
        return
      }

      // Invalidate the state query so that the new state is reflected
      // in the UI
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.appState })
    },
    onError: () => {
      sentryService.captureMessageWithId({
        messageId: 'create-state-failed',
        message: 'Failed to create state',
      })
      showGenericServerError()
    },
  })
}
