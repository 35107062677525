import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'

import { AboutYourClientPartnerFields } from '../../../components/flows/CompassFlow/steps/7_ClientDetails/constants'
import { DefinedBenefitInputFields } from '../../../components/flows/CompassFlow/steps/8_PersonalAssets/components/DefinedBenefitsFieldArray'
import {
  PersonalAssetsInputFields,
  PropertyInputFields,
} from '../../../components/flows/CompassFlow/steps/8_PersonalAssets/constants'
import { ShareInputFields } from '../../../components/flows/CompassFlow/steps/9_BusinessAssets/constants'
import {
  LiabilitiesInputFields,
  MortgageInputFields,
} from '../../../components/flows/CompassFlow/steps/10_Liabilities/constants'
import {
  IncomeInputFields,
  MONTHLY_OUTGOING_TYPE,
  MONTHLY_OUTGOINGS_FOR,
} from '../../../components/flows/CompassFlow/steps/11_Income/constants'
import {
  InterestsInputFields,
  OutflowInputFields,
} from '../../../components/flows/CompassFlow/steps/12_Interests/constants'
import {
  BOOLEAN_QUESTION_OPTION,
  COMPASS_STEPS,
  NOT_APPLICABLE_OPTION,
  OTHER_GOAL_CATEGORY_OPTION,
} from '../../../components/flows/CompassFlow/utils/constants'
import { hasWillFamilyProtection } from '../../../components/flows/CompassFlow/utils/helpers'
import {
  AboutYourClientInput,
  CompassInput,
  CompassStep,
  DefinedBenefitInput,
} from '../../../components/flows/CompassFlow/utils/types'
import {
  CalculateCompassScoresRequest,
  CreateCompassMetadataRequest,
  NormalizedCompassInput,
  NormalizedMortgageInput,
  NormalizedPropertyInput,
} from '../../../types/requests/compass'
import {
  CompassMetadataItem,
  CompassScores,
} from '../../../types/responses/compass'
import { DATE_FORMATS } from '../../constants'
import { formatDate, parseDate } from '../../helpers/helperFunctions'
import apiService from '../apiService'
import compassIncomeService from './compassIncomeService'
import compassQuestionService from './compassQuestionService'

interface CalculateScoresOptions {
  input: CompassInput
  currentStep: CompassStep
  token?: string
}

class CompassRequestService {
  private endpoint = '/v1/compass'

  async calculateScores(
    request: CalculateCompassScoresRequest,
  ): Promise<CompassScores> {
    const response = await apiService.post(
      `${this.endpoint}/calculate`,
      request,
    )

    return response.data
  }

  buildRequest({
    input: initialInput,
    currentStep,
    token,
  }: CalculateScoresOptions): CalculateCompassScoresRequest {
    const filteredInput = this.selectInputsUpUntilCurrentStep(
      initialInput,
      currentStep,
    )

    let input = this.flattenInput(filteredInput)
    input = this.removeEmptyFields(input)
    input = this.removeInapplicableFields(input)
    input = this.removeInapplicableFieldsFromDefinedBenefits(input)
    input = this.removeInapplicableFieldsFromShares(input)
    input = this.removeInapplicableFieldsFromMortgages(input)
    input = this.removeInapplicableFieldsFromOutflows(input)
    input = this.convertDateFormats(input)
    input = this.convertPercentFieldsToFloat(input)
    input = this.handleMonthlyOutgoingsBreakdown(input)
    input = this.normalizeFinancialAdvisor(input)
    input = this.normalizeMortgageBrokerNames(input)

    const request: CalculateCompassScoresRequest = input

    if (token) {
      request.token = token
    }

    return request
  }

  private selectInputsUpUntilCurrentStep(
    input: CompassInput,
    currentStep: CompassStep,
  ): CompassInput {
    if (currentStep === COMPASS_STEPS.interests) {
      return input
    }

    if (currentStep === COMPASS_STEPS.income) {
      return {
        ...input,
        interests: undefined,
      }
    }

    if (currentStep === COMPASS_STEPS.liabilities) {
      return {
        ...input,
        income: undefined,
        interests: undefined,
      }
    }

    if (currentStep === COMPASS_STEPS.businessAssets) {
      return {
        ...input,
        liabilities: undefined,
        income: undefined,
        interests: undefined,
      }
    }

    if (currentStep === COMPASS_STEPS.personalAssets) {
      return {
        ...input,
        businessAssets: undefined,
        liabilities: undefined,
        income: undefined,
        interests: undefined,
      }
    }

    if (
      currentStep === COMPASS_STEPS.clientDetails ||
      currentStep === COMPASS_STEPS.verifyEmail
    ) {
      return {
        ...input,
        personalAssets: undefined,
        businessAssets: undefined,
        liabilities: undefined,
        income: undefined,
        interests: undefined,
      }
    }

    if (currentStep === COMPASS_STEPS.usageType) {
      return {}
    }

    throw new Error(`Unhandled step: ${currentStep}`)
  }

  private flattenInput(input: CompassInput): NormalizedCompassInput {
    return {
      ...(input.aboutYourClient || {}),
      ...(input.personalAssets || {}),
      ...(input.businessAssets || {}),
      ...(input.liabilities || {}),
      ...(input.income || {}),
      ...(input.interests || {}),
    }
  }

  private removeEmptyFields(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    return omitBy(input, (value, key) => {
      if (isUndefined(value) || isNull(value)) {
        return true
      }

      if (isString(value) && value.trim() === '') {
        return true
      }

      // Make a special exception for some fields where the API needs an empty
      // list to build the correct feedbacks.
      const fieldsAllowedToBeEmpty: string[] = [
        LiabilitiesInputFields.family_protection_options,
        LiabilitiesInputFields.professional_protection_options,
      ]

      if (
        isArray(value) &&
        value.length === 0 &&
        !fieldsAllowedToBeEmpty.includes(key)
      ) {
        return true
      }

      return false
    })
  }

  private removeInapplicableFields(
    request: NormalizedCompassInput,
  ): NormalizedCompassInput {
    return omitBy(request, (_value, key) => {
      // Remove previously entered partner's personal details
      if (
        !compassQuestionService.shouldShowPartnerQuestions(
          request.user_private_situation,
        ) &&
        AboutYourClientPartnerFields.includes(key as keyof AboutYourClientInput)
      ) {
        return true
      }

      // Remove previously entered joint properties
      if (
        key === PersonalAssetsInputFields.joint_properties &&
        request.joint_has_properties === false
      ) {
        return true
      }

      // Remove previously entered user properties
      if (
        key === PersonalAssetsInputFields.user_properties &&
        request.user_has_properties === false
      ) {
        return true
      }

      // Remove previously entered partner properties
      if (
        key === PersonalAssetsInputFields.partner_properties &&
        request.partner_has_properties === false
      ) {
        return true
      }

      // Remove previously entered user defined benefits
      if (
        key === PersonalAssetsInputFields.user_defined_benefits &&
        request.user_has_defined_benefit_scheme &&
        [
          BOOLEAN_QUESTION_OPTION.No,
          BOOLEAN_QUESTION_OPTION["I don't know"],
        ].includes(request.user_has_defined_benefit_scheme)
      ) {
        return true
      }

      // Remove previously entered partner defined benefits
      if (
        key === PersonalAssetsInputFields.partner_defined_benefits &&
        request.partner_has_defined_benefit_scheme &&
        [
          BOOLEAN_QUESTION_OPTION.No,
          BOOLEAN_QUESTION_OPTION["I don't know"],
        ].includes(request.partner_has_defined_benefit_scheme)
      ) {
        return true
      }

      // Remove previously entered last updated date of Will
      if (
        key === LiabilitiesInputFields.will_last_updated_date &&
        !hasWillFamilyProtection(request.family_protection_options)
      ) {
        return true
      }

      // Remove previously entered financial dependants
      if (
        key === LiabilitiesInputFields.financial_dependants &&
        request.has_financial_dependants === false
      ) {
        return true
      }

      // Remove previously entered personal loans
      if (
        key === LiabilitiesInputFields.personal_loans &&
        request.has_personal_loans === false
      ) {
        return true
      }

      // Remove previously entered credit card balance
      if (
        key === LiabilitiesInputFields.credit_card &&
        request.has_credit_card === false
      ) {
        return true
      }

      // Remove previously entered outflows
      if (
        key === InterestsInputFields.outflows &&
        request.has_additional_outflows === false
      ) {
        return true
      }

      // Remove previously entered pension contributions
      if (
        request.user_has_pension_contribution !== true &&
        key === IncomeInputFields.user_pension_contribution
      ) {
        return true
      }

      // Remove previously entered partner's pension contributions
      if (
        request.partner_has_pension_contribution !== true &&
        key === IncomeInputFields.partner_pension_contribution
      ) {
        return true
      }

      // Remove previously entered financial advisor
      if (
        key === InterestsInputFields.financial_advisor &&
        request.has_financial_advisor === false
      ) {
        return true
      }
    })
  }

  private convertDateFormats(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    const convert = (dob: string): string => {
      const parsedDate = parseDate(dob, DATE_FORMATS.DAY_MONTH_YEAR)
      return formatDate(parsedDate, DATE_FORMATS.YEAR_MONTH_DAY)
    }

    if (input.user_date_of_birth) {
      input.user_date_of_birth = convert(input.user_date_of_birth)
    }

    if (input.partner_date_of_birth) {
      input.partner_date_of_birth = convert(input.partner_date_of_birth)
    }

    return input
  }

  private convertPercentFieldsToFloat(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    if (input.joint_properties) {
      input.joint_properties = this.convertMortgageInterestRatesToFloat(
        input.joint_properties,
      )
    }

    if (input.user_properties) {
      input.user_properties = this.convertMortgageInterestRatesToFloat(
        input.user_properties,
      )
    }

    if (input.partner_properties) {
      input.partner_properties = this.convertMortgageInterestRatesToFloat(
        input.partner_properties,
      )
    }

    if (input.personal_loans) {
      input.personal_loans = input.personal_loans.map((loan) => ({
        ...loan,
        interest_rate: this.convertIntToFloat(loan.interest_rate),
      }))
    }

    return input
  }

  private convertIntToFloat(originalValue?: number): number | undefined {
    if (typeof originalValue !== 'number') {
      return undefined
    }

    // Convert to a float with 4 decimal places
    return Number((originalValue / 100).toFixed(4))
  }

  private convertMortgageInterestRatesToFloat(
    properties: NormalizedPropertyInput[],
  ): NormalizedPropertyInput[] {
    return properties.map((property) => {
      const mortgage = property.mortgage

      if (!mortgage) {
        return property
      }

      return {
        ...property,
        mortgage: {
          ...mortgage,
          mortgage_interest_rate: this.convertIntToFloat(
            mortgage.mortgage_interest_rate,
          ),
        },
      }
    })
  }

  private handleMonthlyOutgoingsBreakdown(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    // If the user provided a breakdown of their outgoings, then set the
    // `monthly_outgoings` to the breakdown total.
    if (
      input.joint_monthly_outgoings_type === MONTHLY_OUTGOING_TYPE.breakdown
    ) {
      input.joint_monthly_outgoings =
        compassIncomeService.calculateMonthlyOutgoingsTotal(
          input,
          MONTHLY_OUTGOINGS_FOR.joint,
        )
    }

    if (input.user_monthly_outgoings_type === MONTHLY_OUTGOING_TYPE.breakdown) {
      input.user_monthly_outgoings =
        compassIncomeService.calculateMonthlyOutgoingsTotal(
          input,
          MONTHLY_OUTGOINGS_FOR.user,
        )
    }

    if (
      input.partner_monthly_outgoings_type === MONTHLY_OUTGOING_TYPE.breakdown
    ) {
      input.partner_monthly_outgoings =
        compassIncomeService.calculateMonthlyOutgoingsTotal(
          input,
          MONTHLY_OUTGOINGS_FOR.partner,
        )
    }

    return input
  }

  private removeInapplicableFieldsFromShares(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    if (input.shares) {
      input.shares = input.shares.slice().map((share) => {
        if (share.is_incorporated === true) {
          delete share.is_sole_trader
          delete share.unincorporated_name
        }

        if (share.is_incorporated === false) {
          delete share.companies_house_option
        }

        // Won't sell share so remove the other fields
        if (share.want_to_sell_shares === false) {
          return pick(
            share,
            ShareInputFields.company_name,
            ShareInputFields.want_to_sell_shares,
          )
        }

        // Not applicable so remove the other fields
        if (share.want_to_sell_shares === NOT_APPLICABLE_OPTION) {
          return pick(share, ShareInputFields.company_name)
        }

        return share
      })
    }

    return input
  }

  private removeInapplicableFieldsFromDefinedBenefits(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    if (input.user_defined_benefits) {
      input.user_defined_benefits = removeFields(input.user_defined_benefits)
    }

    if (input.partner_defined_benefits) {
      input.partner_defined_benefits = removeFields(
        input.partner_defined_benefits,
      )
    }

    function removeFields(benefits: DefinedBenefitInput[]) {
      return benefits.slice().map((definedBenefit) => {
        const isEmptyIncomeField =
          String(definedBenefit.defined_benefit_annual_gross_benefit).trim()
            .length === 0

        if (isEmptyIncomeField) {
          return omit(
            definedBenefit,
            DefinedBenefitInputFields.defined_benefit_annual_gross_benefit,
          )
        }

        return definedBenefit
      })
    }

    return input
  }

  private removeInapplicableFieldsFromMortgages(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    if (input.joint_properties) {
      input.joint_properties = this.removeMortgageDetailsIfInapplicable(
        input.joint_properties,
      )
      input.joint_properties = this.removeMortgageTermEndDatesIfInapplicable(
        input.joint_properties,
      )
    }

    if (input.user_properties) {
      input.user_properties = this.removeMortgageDetailsIfInapplicable(
        input.user_properties,
      )
      input.user_properties = this.removeMortgageTermEndDatesIfInapplicable(
        input.user_properties,
      )
    }

    if (input.partner_properties) {
      input.partner_properties = this.removeMortgageDetailsIfInapplicable(
        input.partner_properties,
      )
      input.partner_properties = this.removeMortgageTermEndDatesIfInapplicable(
        input.partner_properties,
      )
    }

    return input
  }

  private removeMortgageTermEndDatesIfInapplicable(
    properties: NormalizedPropertyInput[],
  ): NormalizedPropertyInput[] {
    if (properties) {
      return properties.slice().map((property) => {
        const mortgage = property.mortgage

        if (!mortgage || mortgage.fixed_rate_mortgage) {
          return property
        }

        return {
          ...property,
          mortgage: omit(
            mortgage,
            MortgageInputFields.mortgage_term_end_month,
            MortgageInputFields.mortgage_term_end_year,
          ),
        }
      })
    }

    return properties
  }

  private removeMortgageDetailsIfInapplicable(
    properties: NormalizedPropertyInput[],
  ): NormalizedPropertyInput[] {
    if (properties) {
      return properties.slice().map((property) => {
        const mortgage = property.mortgage

        // No mortgage details provided so nothing to remove
        if (!mortgage) {
          return property
        }

        // Has mortgage so keep mortgage details
        if (property.has_outstanding_mortgage) {
          return property
        }

        // Otherwise, remove mortgage details
        return omit(property, PropertyInputFields.mortgage)
      })
    }

    return properties
  }

  private removeInapplicableFieldsFromOutflows(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    if (input.outflows) {
      input.outflows = input.outflows.slice().map((outflow) => {
        if (outflow.outflow_category_key !== OTHER_GOAL_CATEGORY_OPTION) {
          return omit(outflow, OutflowInputFields.outflow_objective)
        }

        return outflow
      })
    }

    return input
  }

  private normalizeFinancialAdvisor(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    if (typeof input.financial_advisor === 'object') {
      input.financial_advisor = input.financial_advisor.name
    }

    return input
  }

  private normalizeMortgageBrokerNames(
    input: NormalizedCompassInput,
  ): NormalizedCompassInput {
    if (input.joint_properties) {
      input.joint_properties = this.normalizeMortgageBrokerName(
        input.joint_properties,
      )
    }

    if (input.user_properties) {
      input.user_properties = this.normalizeMortgageBrokerName(
        input.user_properties,
      )
    }

    if (input.partner_properties) {
      input.partner_properties = this.normalizeMortgageBrokerName(
        input.partner_properties,
      )
    }

    return input
  }

  private normalizeMortgageBrokerName(
    properties: NormalizedPropertyInput[],
  ): NormalizedPropertyInput[] {
    return properties.map((property): NormalizedPropertyInput => {
      const mortgage: NormalizedMortgageInput | undefined = property.mortgage

      // No mortgage on property so need to do anything
      if (!mortgage) {
        return property
      }

      if (mortgage.mortgage_broker_name) {
        mortgage.mortgage_broker_name =
          typeof mortgage.mortgage_broker_name === 'object'
            ? mortgage.mortgage_broker_name.name
            : mortgage.mortgage_broker_name
      }

      return { ...property, mortgage }
    })
  }

  async createCompassMetadata(
    request: CreateCompassMetadataRequest,
  ): Promise<CompassMetadataItem> {
    const response = await apiService.post(`${this.endpoint}/metadata`, request)

    return response.data
  }
}

const compassRequestService = new CompassRequestService()

export default compassRequestService
