import {
  SessionMetadataItem,
  SessionMetadataUser,
} from '../../types/responses/session-metadata'
import { EMAIL_ADDRESSES } from '../constants'
import sentryService from './sentryService'

interface ContactUsEmailContext {
  currentUser: SessionMetadataUser
  currentCompany?: SessionMetadataItem['currentCompany']
}

// https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
class HubspotChatService {
  openChat({ currentUser, currentCompany }: ContactUsEmailContext) {
    // If the Hubspot chat widget is blocked by the browser, open the user's
    // email client instead
    if (this.isBlocked()) {
      let subject = `Support request from ${currentUser.fullName}`

      if (currentCompany) {
        subject += ` at ${currentCompany.name}`
      }

      document.location.href = `mailto:${
        EMAIL_ADDRESSES.rqSupport
      }?subject=${encodeURIComponent(subject)}`
      return
    }

    // Otherwise, try to open the chat widget
    if (window.HubSpotConversations?.widget?.status().loaded) {
      window.HubSpotConversations?.widget?.open()
      return
    }

    // Failed to open chat widget for some reason
    console.warn('Failed to load Hubspot chat')
    sentryService.captureMessageWithId({
      message: 'Failed to load Hubspot chat',
      messageId: 'hubspot-chat-failed',
    })
  }

  // Browser extensions like adblockers may block the chat widget
  isBlocked() {
    const iframeContainerElement = document.getElementById(
      'hubspot-messages-iframe-container',
    )

    return (
      iframeContainerElement === null ||
      window.getComputedStyle(iframeContainerElement).display === 'none'
    )
  }

  loadChatWidget() {
    window.HubSpotConversations?.widget?.load()
  }
}

const hubspotChatService = new HubspotChatService()

export default hubspotChatService
