export function getApiUrl() {
  const hostUrl = `${window.location.protocol}//${window.location.host}`

  if (hostUrl.includes('frontend-feature')) {
    return import.meta.env.VITE_RQ_API_FEATURE_URL
  }

  if (hostUrl.includes('frontend-hotfix')) {
    return import.meta.env.VITE_RQ_API_HOTFIX_URL
  }

  if (hostUrl.includes('host.docker.internal')) {
    return import.meta.env.VITE_RQ_API_PRIVATE_URL
  }

  return import.meta.env.VITE_RQ_API_URL
}
