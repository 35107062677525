import * as Sentry from '@sentry/react'

import { isDevelopment } from '../lib/helpers/helperFunctions'
import { useCurrentUser } from './useCurrentUser'

export function useShowSentryReportDialog() {
  const currentUser = useCurrentUser()

  return function showSentryReportDialog(eventId: string) {
    if (isDevelopment()) {
      return
    }

    Sentry.showReportDialog({
      eventId,
      user: {
        name: currentUser?.fullName,
        email: currentUser?.email,
      },
    })
  }
}
