import { CompanyProfileSearchParams } from '../components/misc/CompanyProfile/CompanyProfile'
import { ServiceAreaIds } from '../components/misc/CompassPartner/helpers'
import { CompanyTypeCode } from '../types/misc'
import { GetReferralFirmIndividualsParams } from '../types/requests/companies'
import { GetUserInvitesParams } from '../types/requests/invite-users'
import {
  GetPanelPartnersParams,
  GetPanelsParams,
} from './services/panelService'

export const QUERY_KEYS = {
  appState: ['app-state'],
  client: {
    item: (id: number) => ['client', { id }],
  },
  companyBankDetails: ['company-bank-details'],
  companyByReferralCode: (referralCode: string) => [
    'company-by-referral-code',
    { referralCode },
  ],
  companyCompassContents: ['company-compass-contents'],
  compassPartners: (params?: { companyId: number }) =>
    params ? ['compass-partners', params] : ['compass-partners'],
  compassReportSharings: ['compass-report-sharings'],
  compassReport: (token: string) => ['compass-report', { token }],
  companyProfile: (
    params?: Pick<CompanyProfileSearchParams, 'identifierType' | 'identifier'>,
  ) => (params ? ['company-profile', params] : ['company-profile']),
  companyEmails: {
    all: ['company-emails'],
    compassInviteEmail: () => [
      QUERY_KEYS.companyEmails.all,
      { emailType: 'COMPASS_INVITE' },
    ],
  },
  latestCompassReport: ['latest-compass-report'],
  latestCompletedCompassReport: (clientId: number) => [
    'latest-completed-compass-report',
    { clientId },
  ],
  onboardingChecklist: () => ['onboarding-checklist'],
  panels: (params?: GetPanelsParams) =>
    params ? ['panels', params] : ['panels'],
  panelPartners: (params?: GetPanelPartnersParams) =>
    params ? ['panel-partners', params] : ['panel-partners'],
  panelApprovals: (fromCompanyId: number) => [
    'panel-approvals',
    { fromCompanyId },
  ],
  panel: (panelId?: number) => (panelId ? ['panels', { panelId }] : ['panels']),
  panelAgreements: (panelId?: number) =>
    panelId ? ['panel-agreements', { panelId }] : ['panel-agreements'],
  panelStats: () => ['panel-stats'],
  recommendedPartners: () => ['recommended-partners'],
  recommendedPartner: (companyId: number) => [
    'recommended-partners',
    { companyId },
  ],
  referClient: {
    all: ['refer-client'],
    referralFirm: (id: number) => ['referral-firm', { id }],
    referralFirms: (params?: {
      companyTypeCode: CompanyTypeCode
      serviceAreaIds: ServiceAreaIds
    }) => (params ? ['referral-firms', params] : ['referral-firms']),
    referralFirmIndividuals: (params?: GetReferralFirmIndividualsParams) =>
      params
        ? ['referral-firm-individuals', params]
        : ['referral-firm-individuals'],
  },
  referralWithESignatureDetails: (id: number) => [
    'referral-esignature',
    { id },
  ],
  rqCertifiedAssessment: ['rq-certified-assessment'],
  referralCommissionConsent: (id: number) => [
    'request-commisson-consent',
    { id },
  ],
  referralSummary: (id: number) => ['referral-summary', { id }],
  referrals: () => ['referrals'],
  recentReferrals: () => ['recentReferrals'],
  referralNotes: (id: number) => ['referral-notes', { id }] as const,
  referralNoteMessage: (id: number) =>
    ['referral-note-message', { id }] as const,
  referralNoteMessages: (id: number) =>
    ['referral-note-messages', { id }] as const,
  reviews: (companyId: number) => ['reviews', { companyId }],
  userInvites: (params?: GetUserInvitesParams) =>
    params ? ['user-invites', params] : ['user-invites'],
}
