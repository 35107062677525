import classNames from 'classnames'
import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
}

const CardHeading: React.FC<Props> = ({ children, className }) => (
  <h4 className={classNames('text-black fw-bolder', className)}>{children}</h4>
)

export default CardHeading
