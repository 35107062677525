import { ReferralsTab } from '../../pages/referrals/ReferralsPage'
import { CommercialAgreementTypeOption } from '../../redux/slices/commercialAgreementsForm'
import { OrderDirection, PaginationFilters } from '../../types/api'
import {
  UpdateReferralApprovalStatusRequest,
  UpdateReferralClientRequest,
} from '../../types/requests/clients'
import {
  CreateNewReferralMetadataRequest,
  CreateReferralClientConsentLetterPreviewRequest,
  CreateReferralEmailPreviewRequest,
  CreateReferralRequest,
  RequestReferralCallbackRequest,
  SubmitESignatureConsentLetterRequest,
} from '../../types/requests/referrals'
import {
  CheckCanReferItem,
  NewReferralMetadataItem,
  ReferralCollection,
  ReferralEmailItem,
  ReferralGuestItem,
  ReferralItem,
  ReferralsTableMetadataItem,
  ReferralWithESignatureDetailsItem,
  SubmitESignatureConsentLetterResponse,
  WithdrawGdprConsentForReferralResponse,
} from '../../types/responses/referrals'
import { REFERRAL_STATUS_ID } from '../constants'
import apiService from './apiService'
import commercialAgreementService from './commercialAgreementService'
import legislationService from './legislationService'

export type ReferralCollectionParams = PaginationFilters &
  ReferralCollectionSearchFilters &
  ReferralCollectionOrderFilters

export type ReferralCollectionSearchFilters = {
  'referralDate[after]'?: string
  'referralDate[before]'?: string
  'dateConsented[after]'?: string
  'dateConsented[before]'?: string
  'user.fullName'?: string
  'client.clientId'?: ID
  'client.fullName'?: ID
  'fromClient.email'?: string
  'fromCompany.id'?: ID
  'toCompany.id'?: ID
  'fromCompany.name'?: string
  'toCompany.name'?: string
  'status.id'?: string
  introducedTo?: string
  'services.serviceArea.id'?: ID[]
  'fromCompany.networks.id'?: ID[]
  'toCompany.networks.id'?: ID[]
  isEra?: boolean
  reason?: string
  approvalStatus?: string
}

export type ReferralCollectionOrderFilters = {
  'order[referralDate]'?: OrderDirection
  'order[dateConsented]'?: OrderDirection
  'order[user.firstName]'?: OrderDirection
  'order[client.firstName]'?: OrderDirection
  'order[client.clientId]'?: OrderDirection
  'order[toCompany.name]'?: OrderDirection
  'order[fromCompany.name]'?: OrderDirection
  'order[status.id]'?: OrderDirection
  'order[services.serviceArea.name]'?: OrderDirection
  'order[isEra]'?: OrderDirection
  'order[fromCompany.networks.id]'?: OrderDirection
  'order[toCompany.networks.id]'?: OrderDirection
}

export class ReferralService {
  private endpoint = '/v1/referrals'

  async getReferrals(
    filters: ReferralCollectionParams,
  ): Promise<ReferralCollection> {
    const response = await apiService.get(this.endpoint, {
      params: filters,
    })

    return response.data
  }

  async getReferralsTableMetadata(
    companyId: number,
    tab: ReferralsTab,
  ): Promise<ReferralsTableMetadataItem> {
    const response = await apiService.get<ReferralsTableMetadataItem>(
      `${this.endpoint}/table-metadata`,
      { params: { companyId, direction: tab } },
    )

    return response.data
  }

  async getReferralSummary(referralId: ID) {
    const referral = await this.getReferral(referralId)
    const hasReferredForFcaService = referral.services.some(
      (service) => service['@type'] === 'fca-service',
    )

    // If referral is not for an FCA service, there's no need to
    // fetch legislations
    if (!hasReferredForFcaService) {
      return {
        referral,
        legislations: [],
      }
    }

    // Otherwise, let's fetch the legislations
    const serviceAreaIds = referral.services.map(
      (service) => service.serviceArea.id,
    )
    const legisationsData =
      await legislationService.getLegislationsByFcaServiceAreaIds(
        serviceAreaIds,
      )

    return { referral, legislations: legisationsData['hydra:member'] }
  }

  async getReferral(referralId: ID): Promise<ReferralItem> {
    const response = await apiService.get(`${this.endpoint}/${referralId}`)

    return response.data
  }

  async getReferralWithESignatureDetails(
    referralId: number,
    callbackCode: string,
  ): Promise<ReferralWithESignatureDetailsItem> {
    const response = await apiService.get(
      `${this.endpoint}/${referralId}/guest/with-esignature-details`,
      { params: { callbackCode } },
    )

    return response.data
  }

  async createReferralESignatureLetterPreview(
    request: CreateReferralClientConsentLetterPreviewRequest,
  ): Promise<ReferralWithESignatureDetailsItem> {
    const response = await apiService.post(
      `${this.endpoint}/esignature-consent-letter/preview`,
      request,
    )

    return response.data
  }

  async getReferralByCallbackCode(
    referralId: number,
    callbackCode: string,
  ): Promise<ReferralGuestItem> {
    const response = await apiService.get<ReferralGuestItem>(
      `${this.endpoint}/${referralId}/guest`,
      { params: { callbackCode } },
    )

    return response.data
  }

  async getReferralByWithdrawGdprConsentCode(
    referralId: number,
    withdrawGdprConsentCode: string,
  ): Promise<ReferralGuestItem> {
    const response = await apiService.get<ReferralGuestItem>(
      `${this.endpoint}/${referralId}/guest`,
      { params: { withdrawGdprConsentCode } },
    )

    return response.data
  }

  async createReferral(request: CreateReferralRequest): Promise<ReferralItem> {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async confirmClientConsentForReferral(
    referralId: number,
    callbackCode: string,
  ): Promise<ReferralGuestItem> {
    const response = await apiService.patch<ReferralGuestItem>(
      `${this.endpoint}/${referralId}/guest`,
      {
        status:
          '/v1/referral-statuses/' +
          REFERRAL_STATUS_ID.AWAITING_ADVISER_TO_CONTACT_CLIENT,
      },
      { params: { callbackCode } },
    )

    return response.data
  }

  async getReferralsCSV(filters: ReferralCollectionParams) {
    delete filters.page
    delete filters.pageSize
    delete filters.pagination
    const response = await apiService.get(`${this.endpoint}.csv`, {
      params: { ...filters, pagination: false },
      responseType: 'blob',
    })
    return response.data
  }

  async requestReferralCallback(
    referralId: number,
    callbackCode: string,
    request: RequestReferralCallbackRequest,
  ): Promise<ReferralItem> {
    const response = await apiService.patch<ReferralItem>(
      `${this.endpoint}/${referralId}/guest`,
      request,
      { params: { callbackCode } },
    )

    return response.data
  }

  async createReferralEmailPreview(
    request: CreateReferralEmailPreviewRequest,
  ): Promise<ReferralEmailItem> {
    const response = await apiService.post(
      `${this.endpoint}/email-preview`,
      request,
    )

    return response.data
  }

  async provideGdprConsentForReferral(referralId: ID) {
    const response = await apiService.patch(`${this.endpoint}/${referralId}`, {
      hasGdprConsent: true,
    })

    return response.data
  }

  async withdrawGdprConsentForReferral(
    referralId: ID,
    withdrawConsentCode: string,
  ): Promise<WithdrawGdprConsentForReferralResponse> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/guest/withdraw-gdpr-consent`,
      { hasGdprConsent: false },
      { params: { withdrawGdprConsentCode: withdrawConsentCode } },
    )

    return response.data
  }

  async updateReferralStatus(
    referralId: ID,
    newStatusIri: IRI,
  ): Promise<ReferralItem> {
    const response = await apiService.patch<ReferralItem>(
      `${this.endpoint}/${referralId}/status`,
      {
        status: newStatusIri,
      },
    )

    return response.data
  }

  async attachCompassReportToReferral(referralId: ID, compassReportIri: IRI) {
    const response = await apiService.patch(`${this.endpoint}/${referralId}`, {
      compassReport: compassReportIri,
    })

    return response.data
  }

  async unattachCompassReportFromReferral(referralId: ID) {
    const response = await apiService.patch<ReferralItem>(
      `${this.endpoint}/${referralId}`,
      { compassReport: null },
    )

    return response.data
  }

  async createNewReferralMetadata(
    request: CreateNewReferralMetadataRequest,
  ): Promise<NewReferralMetadataItem> {
    const response = await apiService.post<NewReferralMetadataItem>(
      `${this.endpoint}/new-referral-metadata`,
      request,
    )

    return response.data
  }

  async checkCanRefer(toCompanyIri: IRI): Promise<CheckCanReferItem> {
    const response = await apiService.post(`${this.endpoint}/check-can-refer`, {
      toCompany: toCompanyIri,
    })

    return response.data
  }

  async updateReferralUser(referralId: number, user: IRI): Promise<unknown> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/user`,
      { user },
    )

    return response.data
  }

  async updateReferralClient(
    referralId: number,
    request: UpdateReferralClientRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/update-client`,
      request,
    )

    return response.data
  }

  async updateReferralApprovalStatus(
    referralId: ID,
    request: UpdateReferralApprovalStatusRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/update-approval-status`,
      request,
    )

    return response.data
  }

  async updateReferralIntroducedToUsers(
    referralId: number,
    userIds: number[],
  ): Promise<unknown> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/introduced-to-users`,
      { userIds },
    )

    return response.data
  }

  async submitESignatureConsentLetter(params: {
    referralId: number
    callbackCode: string
    request: SubmitESignatureConsentLetterRequest
  }): Promise<SubmitESignatureConsentLetterResponse> {
    const { referralId, request, callbackCode } = params

    const response = await apiService.post(
      `${this.endpoint}/${referralId}/guest/esignature-consent-letter/submit`,
      request,
      { params: { callbackCode } },
    )

    return response.data
  }

  async remindReferralApprovalRequests(referralId: number): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${referralId}/remind-approval-requests`,
      {},
    )

    return response.data
  }

  shouldShowCommissionJustification(
    agreementType: CommercialAgreementTypeOption | undefined,
  ): boolean {
    return commercialAgreementService.isFeeSharingAgreement(agreementType)
  }
}

const referralService = new ReferralService()
export default referralService
