import classNames from 'classnames'
import React, { CSSProperties } from 'react'

interface Props {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

const FormGroup: React.FC<Props> = ({ children, className, style }) => (
  <div className={classNames('mb-4 avoid-page-break', className)} style={style}>
    {children}
  </div>
)

export default FormGroup
